const stationConverter = {
  convertToFront: function (station) {
    station.services = station.services ? station.services.split(';') : []
    return station
  },
  convertFromFront: function (station) {
    station.services = Array.isArray(station.services) ? station.services.join(';') : null
    return station
  }
}

function getParcourStation(station) {
  return {
    spontaneous: station.spontaneous,
    garanteed: station.garanteed,
    isPMR: station.isPMR,
    codeUic: station.codeUic,
    codeGare: station.codeGare
  }
}

const filterStations = (stations, searchQuery) => stations.filter(s => s.name && searchQuery && s.name.toLowerCase().includes(searchQuery.toLowerCase().trim()));

const addEquipments = (station, equipment) => {
  // Avoid modifying the original 'station' object directly
  const updatedStation = { ...station };

  updatedStation.equipment = equipment;

  return updatedStation;
}

export { filterStations, stationConverter, addEquipments, getParcourStation }
