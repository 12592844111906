export const STATION_FULL_ACCESSIBILITY = 'full_accessibility'
export const STATION_PARTIAL_ACCESSIBILITY = 'partial_accessibility'
export const STATION_LIMITED_ACCESSIBILITY = 'limited_accessibility'
export const STATION_NO_ACCESSIBILITY = 'no_accessibility'

export const ELEVATOR_IN_MAINTENANCE = 'EN REPARATION'
export const ELEVATOR_IN_SERVICE = 'EN SERVICE'
export const ELEVATOR_INACTIVE = 'INACTIF'
export const ELEVATOR_SCUM = 'REBUT'
export const ELEVATOR_FONCTIONNEMENT_OK = 'OK'
export const ELEVATOR_FONCTIONNEMENT_KO = 'KO'

export const SPONTANE = 'Spontané'
export const ALERT_NOTIFS = 'alertNotif'

export const PRESTA_NATURE_ASSISTANCE_GARE = 'Gare'
export const PRESTA_NATURE_ASSISTANCE_BORD = 'Bord'
export const PRESTA_NATURE_ASSISTANCE_ROUTE = 'Route'

export const NO_ELIGIBLE_ASSISTANCE_MOTIF = 'nonEligibleAssistant'

export const journeyDataObject = {
  trainNumber: null,
  transporter: null,
  datePrestation: null,
  dateAsked: null,
  hourAsked: '',
  trainCar: null,
  trainPlace: null,
  missionCode: null,
  transportMode: null,
  service: null,
  garantee: null,
  origin: 'Front',
  status: 'À réaliser',
  statusTN: 'todo',
  passages: []
};

export const ADMINISTRATOR = 'ADMINISTRATOR'
export const REFERENT = 'REFERENT'
export const ADMINTRANSPO = 'ADMINTRANSPO'
export const REFTRANSPO = 'REFTRANSPO'
export const USER = 'USER'
export const USERTRANSPO = 'USERTRANSPO'
export const TRANSPORTER_ROLE = 'TRANSPORTER'
export const TRANSPORTER = 'Transporteur'

export const GARECO = 'garesAndConnexions'
export const GROUND = 'Gare'
