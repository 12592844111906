<template>
    <div class='stationDetail'>
      <div v-if="!editOpen">
        <div class='socaContent socaContentNarrow'>
          <div class="pageTitleLight">{{$t('stations.list.file') }}</div>
          <div class="stationHeader">
            <h2 class="stationName">{{ station.name }}<UnlinkedIndicator :element="station" type="station"/></h2>
            <div class="regionName">{{ $sncfRefs.getRegionLabelByCode(station.region) }}</div>
            <div class="stationUserLastModif" v-if="station.updateDate && station.updateAccount">
              <span>{{ $t('common.updatedAt') }}&nbsp;{{ updatedDate }}&nbsp;{{ $t('common.at') }}&nbsp;{{ updatedHour }}</span><br/>
              <span>{{ $t('common.by') }}&nbsp;{{ station.updateAccount }}</span>
            </div>
          </div>
          <div class="resume">
            <div class="resumeColumns">
              <div class="resumeColumn" v-if='station.spontaneous || station.garanteed || station.isPMR || station.soca'>
                <div class="resumeLine" v-if="station.spontaneous">
                  <div class="spontaneousIcon" ><svgicon icon="thunder"/></div>
                  <span>{{ $t('station.spontaneous') }}</span>
                </div>
                <div class="resumeLine"  v-if="station.garanteed">
                  <div class="garanteedIcon"><svgicon icon="star" /></div>
                  <span>{{ $t('station.garanteed') }}</span>
                </div>
                <div class="resumeLine" v-if="station.isPMR">
                  <div class="pmrIcon">PMR</div>
                  <span>{{ $t('station.pmr') }}</span>
                </div>
                <div class="resumeLine" v-if="station.soca">
                  <div class="socaIcon">SOCA</div>
                  <span>{{ $t('station.useSoca') }}</span>
                </div>
              </div>
              <div class="resumeColumn">
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_FULL_ACCESSIBILITY">
                  <svgicon class="icons fullAccessibilityStation" icon="accessible_for_all"/>
                  <span>{{ $t('station.fullAccessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_PARTIAL_ACCESSIBILITY">
                  <svgicon class="icons partialAccessibilityStation" icon="accessible"/>
                  <span>{{ $t('station.partialAccessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_LIMITED_ACCESSIBILITY">
                  <svgicon class="icons limitedAccessibilityStation" icon="barely_accessible"/>
                  <span>{{ $t('station.limitedAccessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_NO_ACCESSIBILITY || station.accessibilityLevel === null">
                  <svgicon class="icons noAccessibilityStation" icon="inaccessible"/>
                  <span>{{ $t('station.noAccessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="!station.equipment">
                  <svgicon class="icons elevatorNok" icon="elevator"/>
                  <span>{{ $t('station.unavailableElevatorData') }}</span>
                </div>
                <div class="resumeLine" v-if="elevatorOk">
                  <svgicon class="icons elevator" icon="elevator"/>
                  <span>{{ $t('station.elevatorOk') }}</span>
                </div>
                <div class="resumeLine" v-if="elevatorNok">
                  <svgicon class="icons elevatorNok" icon="elevator"/>
                  <span>{{ $t('station.elevatorNok') }}</span>
                </div>
                <div class="resumeLine" v-if="noElevator">
                  <svgicon class="icons elevator" icon="elevator"/>
                  <span>{{ $t('station.noElevator') }}</span>
                </div>
                <div class="resumeLine" v-if="station.compatibleHardware">
                  <svgicon class="icons hardware" icon="hardware"/>
                  <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ station.compatibleHardware }}</span>
                </div>
                <div class="resumeLine" v-else>
                  <svgicon class="icons hardware" icon="hardware"/>
                  <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ $t('station.noComptibleHardware') }}</span>
                </div>
              </div>
            </div>
            <div class="resumeColumn">
              <div class="resumeLine finalLine" v-if="station.alerts && station.alerts.length > 0">
                  <svgicon class="icons exceptionnal" icon="exceptionnal" />
                <span>{{ $t('station.exceptionalSituationCurrently') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="accordionContainer socaContent socaContentNarrow socaContent-eatContentPadding">
          <ul class="accordion" data-accordion data-allow-all-closed="true">
            <li class="accordion-item is-active" data-accordion-item>
              <a href="#" class="accordion-title">{{ $t('station.personnel') }}<svgicon icon="arrow" class="arrow" /></a>
              <div class="accordion-content" data-tab-content>
                <h3 v-if="station.assistActors && station.assistActors.length === 0">{{ $t('station.noAssistActors') }}</h3>
                <div v-for="assistActor in station.assistActors" :key="assistActor.gcActorCode">
                  <div class="responsible">
                    <span class="resp">{{ $t('station.resp') }}: {{ $sncfRefs.getAssistResponsible(assistActor.gcActorCode !== null) }}</span>
                    <span class="incumbent">{{ $t('station.incumbent') }}: {{ assistActor.gcActorCode !== null ? assistActor.gcActor.name : assistActor.transporter.name }}</span>
                  </div>
                  <ul class="accordion" data-accordion data-allow-all-closed="true">
                    <li class="accordion-item is-active" data-accordion-item>
                      <a href="#" class="accordion-title">{{ $t('station.charge') }}<svgicon icon="arrow" class="arrow" /></a>
                      <div class="accordion-content" data-tab-content>
                        <div class="grid-x grid-margin-x">
                          <div class="cell small-12 large-6">
                            <div class="pmrIcons oneStationDetail">
                              <div class="pmrIconLine" :class="{ isActive: assistActor.handicapMoteur}">
                                <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="handicap"/><span class="checkMark"><svgicon icon="check"/></span></div>
                                <span class="value">{{ $t('station.motor') }}</span>
                              </div>
                              <div class="pmrIconLine" :class="{ isActive: assistActor.handicapVisuel }">
                                <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="eye"/><span class="checkMark"><svgicon icon="check"/></span></div>
                                <span class="value">{{ $t('station.visual') }}</span>
                              </div>
                              <div class="pmrIconLine" :class="{ isActive: assistActor.handicapMental }">
                                <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="face"/><span class="checkMark"><svgicon icon="check"/></span></div>
                                <span class="value">{{ $t('station.mental') }}</span>
                              </div>
                              <div class="pmrIconLine" :class="{ isActive: assistActor.handicapAudio }">
                                <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="ear"/><span class="checkMark"><svgicon icon="check"/></span></div>
                                <span class="value">{{ $t('station.audio') }}</span>
                              </div>
                              <div class="pmrIconLine" :class="{ isActive: assistActor.handicapAutre }">
                                <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="canne"/><span class="checkMark"><svgicon icon="check"/></span></div>
                                <span class="value">{{ $t('station.handicapOther') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="cell small-12 large-6">
                            <div class="oneStationDetail">
                            <div class="cell grid-x grid-margin-x" v-for="dayConst in daysConstant" :key="dayConst">
                                <span class="day cell small-4 medium-4">{{ $t('common.'+dayConst) }}</span>
                                <span v-if="assistActorClosed(assistActor, dayConst)" class="value cell small-8 medium-8">{{ $t('common.closed') }}</span>
                                <span v-if="!assistActorClosed(assistActor, dayConst)" class="value cell small-8 medium-8">{{formatHours(assistActor[dayConst+'Agent'])}}</span>
                                <span v-if="!assistActorClosed(assistActor, dayConst)" class="value cell small-4 medium-4"></span>
                                <span v-if="!assistActorClosed(assistActor, dayConst)" class="value cell small-8 medium-8">{{formatHours(assistActor[dayConst+'Agent2'])}}</span>
                                <span v-if="!assistActorClosed(assistActor, dayConst)" class="value cell small-4 medium-4"></span>
                                <span v-if="!assistActorClosed(assistActor, dayConst)" class="value cell small-8 medium-8">{{formatHours(assistActor[dayConst+'Agent3'])}}</span>
                              </div>
                            </div>
                            <div class="cell small-12 large-6" v-if="assistActor.gcActorCode !== null">
                              <div class="oneStationDetail">
                                <label>{{ $t('station.assistanceTrain') }}</label>
                                <span v-if="typeof assistActor.assistanceTrain === 'boolean'" class="value">{{$t(`common.answer.${assistActor.assistanceTrain}`)}}</span>
                                <span v-else class="value">{{$t(`common.answer.undefined`)}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="accordion-item" data-accordion-item>
                      <a href="#" class="accordion-title">{{ $t('station.coordonnee')}}<svgicon icon="arrow" class="arrow" /></a>
                      <div class="accordion-content" data-tab-content>
                        <div class="grid-x grid-margin-x">
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.leadName') }}</label>
                            <span class="value">{{assistActor.leadName || '-'}}</span>
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.leadMail') }}</label>
                            <EmailLink class="value" :value="assistActor.leadMail" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.assistName') }}</label>
                            <span class="value">{{assistActor.assistName || '-'}}</span>
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.assistMail') }}</label>
                            <EmailLink class="value" :value="assistActor.assistMail" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.internal1') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.internal1" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.internal2') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.internal2" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.external1') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.external1" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.external2') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.external2" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.mobile1') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.mobile1" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.mobile2') }}</label>
                            <PhoneCallLink class="value" :value="assistActor.mobile2" />
                          </div>
                          <div class="oneStationDetail cell small-12 large-6">
                            <label>{{ $t('station.emailAssist') }}</label>
                            <EmailLink class="value" :value="assistActor.emailAssist" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="accordion-item" data-accordion-item>
              <a href="#" class="accordion-title">{{ $t('station.realTimeElevator')}}<svgicon icon="arrow" class="arrow" /></a>
              <div class="accordion-content" data-tab-content>
                <div class="grid-x grid-margin-x">
                  <div class="equipmentDetail cell small-12 large-12">
                    <h3 v-if="noElevator && noEscalator">{{ $t('station.noElevator') }}</h3>
                    <div v-else-if="station.equipment && station.equipment.elevators && station.equipment.elevators.length !== 0">
                      <h3>{{ $t('station.elevatorCondition') }}</h3>
                      <ul class="oneStationElevaticDetail">
                        <li class="cell small-12 large-12" v-for="elevator in station.equipment.elevators" :key="elevator.id">
                          <div class="elevatorDetail">
                            {{elevator.libelle}}
                          </div>
                          <div class="availability" v-if="elevator.statut === ELEVATOR_IN_SERVICE && elevator.fonctionnement && elevator.fonctionnement.etat  === ELEVATOR_FONCTIONNEMENT_OK">
                            <div class="validIcon" > <svgicon icon="accept" /></div>
                              {{ $t('station.workingElevator') }}
                          </div>
                          <div class="availability" v-else-if="elevator.statut === ELEVATOR_IN_MAINTENANCE || (elevator.fonctionnement && elevator.fonctionnement.etat  === ELEVATOR_FONCTIONNEMENT_KO)">
                            <div class="invalidIcon" > <svgicon icon="refuse" /></div>
                              {{ $t('station.notWorkingElevator') }}
                          </div>
                          <div class="availability" v-else>
                              {{ $t('station.noAvilableData') }}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="station.equipment && station.equipment.escalators && station.equipment.escalators.length !== 0">
                      <h3>{{ $t('station.escalatorCondition') }}</h3>
                      <ul class="oneStationElevaticDetail">
                        <li class="cell small-12 large-12" v-for="escalator in station.equipment.escalators" :key="escalator.id">
                          <div class="elevatorDetail">
                            {{escalator.libelle}}
                          </div>
                          <div class="availability" v-if="escalator.fonctionnement && escalator.fonctionnement.etat  === 'OK'">
                            <div class="validIcon" > <svgicon icon="accept" /></div>
                              {{ $t('station.workingElevator') }}
                          </div>
                          <div class="availability" v-else-if="escalator.fonctionnement && escalator.fonctionnement.etat  === 'KO'">
                            <div class="invalidIcon" > <svgicon icon="refuse" /></div>
                              {{ $t('station.notWorkingElevator') }}
                          </div>
                          <div class="availability" v-else>
                              {{ $t('station.noAvilableData') }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="accordion-item" data-accordion-item>
              <a href="#" class="accordion-title">{{ $t('station.substitution')}}<svgicon icon="arrow" class="arrow" /></a>
              <div class="accordion-content" data-tab-content>
                <div class="grid-x grid-margin-x">
                  <div class="cell small-12 large-6">
                    <div class="pmrIcons oneStationDetail">
                      <div class="pmrIconLine" :class="{ isActive: station.handicapMoteurTaxi }">
                        <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="handicap"/><span class="checkMark"><svgicon icon="check"/></span></div>
                        <span class="value">{{ $t('station.motor') }}</span>
                      </div>
                      <div class="pmrIconLine" :class="{ isActive: station.handicapVisuelTaxi }">
                        <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="eye"/><span class="checkMark"><svgicon icon="check"/></span></div>
                        <span class="value">{{ $t('station.visual') }}</span>
                      </div>
                      <div class="pmrIconLine" :class="{ isActive: station.handicapMentalTaxi }">
                        <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="face"/><span class="checkMark"><svgicon icon="check"/></span></div>
                        <span class="value">{{ $t('station.mental') }}</span>
                      </div>
                      <div class="pmrIconLine" :class="{ isActive: station.handicapAudioTaxi }">
                        <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="ear"/><span class="checkMark"><svgicon icon="check"/></span></div>
                        <span class="value">{{ $t('station.audio') }}</span>
                      </div>
                      <div class="pmrIconLine" :class="{ isActive: station.handicapAutreTaxi }">
                        <div class="pmrIconWrapper"><svgicon class="pmrIcon" icon="canne"/><span class="checkMark"><svgicon icon="check"/></span></div>
                        <span class="value">{{ $t('station.handicapOther') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="cell small-12 large-6">
                    <div class="oneStationDetail">
                      <div class="cell grid-x grid-margin-x" v-for="dayConst in daysConstant" :key="dayConst">
                        <span class="day cell small-4 medium-4">{{ $t('common.'+dayConst) }}</span>
                        <span class="value cell small-8 medium-8">{{formatHours(station[dayConst+'Taxi'])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="accordion-item" data-accordion-item>
              <a href="#" class="accordion-title">{{ $t('station.exceptionalSituations')}}<svgicon icon="arrow" class="arrow" /></a>
              <div class="accordion-content" data-tab-content>
                <div class="formElement cell small-12 large-6" v-if="station.alerts && station.alerts.length === 0">
                  <label>{{ $t('station.exceptionalSituations') }}</label>
                  <div class="formRadio">
                    <label>{{$t('common.no') }}</label>
                  </div>
                </div>
                <div v-else class="grid-x grid-margin-x">
                  <Alert :alerts="station.alerts" :updatable="false"/>
                </div>
              </div>
            </li>

            <li class="accordion-item" data-accordion-item>
              <a href="#" class="accordion-title">{{ $t('station.information')}}<svgicon icon="arrow" class="arrow" /></a>
              <div class="accordion-content" data-tab-content>
                <div class="grid-x grid-margin-x">
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.nameOfficial') }}</label>
                    <span class="value">{{station.sfName || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.socaName') }}</label>
                    <span class="value">{{station.name || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.codeUic') }}</label>
                    <span class="value">{{station.codeUic || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.type') }}</label>
                    <span class="value">{{station.type || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.garanteed') }}</label>
                    <span class="value">{{ $t('common.answer.' + station.garanteed) }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.spontaneous') }}</label>
                    <span class="value">{{ $t('common.answer.' + station.spontaneous) }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.services') }}</label>
                    <span class="value">{{ station.services && station.services.join ? station.services.join(' / ') : '' }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.pmr') }}</label>
                    <span class="value">{{ $t('common.answer.' + station.isPMR) }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.soca') }}</label>
                    <span class="value">{{ $t('common.answer.' + station.soca) }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.deploySoca') }}</label>
                    <span class="value">{{this.dateDeploy}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.stopAndGo') }}</label>
                    <span class="value">{{ $t('common.answer.' + station.stopAndGo) }}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.externalProvider') }}</label>
                    <span class="value">{{$t('common.answer.' + station.externalProvider)}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.backupStation') }} 1</label>
                    <span class="value">{{station.gareRabattement1 && station.gareRabattement1.name || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.tripDuration') }} 1</label>
                    <span class="value">{{station.tripToBackupStation1 || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.backupStation') }} 2</label>
                    <span class="value">{{station.gareRabattement2 && station.gareRabattement2.name || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.tripDuration') }} 2</label>
                    <span class="value">{{station.tripToBackupStation2 || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.backupStation') }} 3</label>
                    <span class="value">{{station.gareRabattement3 && station.gareRabattement3.name || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.tripDuration') }} 3</label>
                    <span class="value">{{station.tripToBackupStation3 || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.link') }}</label>
                    <span class="value">{{station.link || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.country') }}</label>
                    <span class="value">{{station.country || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.region') }}</label>
                    <span class="value">{{$sncfRefs.getRegionLabelByCode(station.region) || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.agency') }}</label>
                    <span class="value">{{station.agency || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.accessibility') }}</label>
                    <span class="value">{{station.accessibility ? $t('station.access.'+station.accessibility) : '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.oldRegion') }}</label>
                    <span class="value">{{station.oldRegion || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.axis') }}</label>
                    <span class="value">{{station.axis || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.ligne') }}</label>
                    <span class="value">{{station.line || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                      <label v-tooltip="{
                        content: $t('station.boardingAidTooltip'),
                        trigger: 'hover click',
                      }">{{ $t('station.boardingAid') }}</label>
                      <span class="value">{{$t('common.answer.' + station.boardingAid)}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.meetingPoint') }}</label>
                    <span class="value">{{station.meetingPoint || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.extraInfo') }}</label>
                    <span class="value">{{station.extraInfo || '-'}}</span>
                  </div>
                  <div class="oneStationDetail cell small-12 large-6">
                    <label>{{ $t('station.planningAcquit') }}</label>
                    <span class="value">{{$t('common.answer.' + station.planningAcquit)}}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class='socaContent socaContentNarrow'>
          <button class='button-theme stationUpdateButton' v-if="canEdit" @click="editOpen = true">{{$t('station.modify')}}</button>
        </div>
      </div>
      <div v-if="editOpen">
        <StationEdit :stationIn="station" @updateIsComplete="updateIsComplete" />
      </div>
    </div>
</template>

<script>
import StationEdit from '@/components/Stations/Update'
import PhoneCallLink from '@/components/Common/PhoneCallLink'
import EmailLink from '@/components/Common/EmailLink'
import UnlinkedIndicator from '@/components/Common/UnlinkedIndicator'
import { STATION_FULL_ACCESSIBILITY, STATION_PARTIAL_ACCESSIBILITY, STATION_LIMITED_ACCESSIBILITY, STATION_NO_ACCESSIBILITY, ELEVATOR_IN_SERVICE, ELEVATOR_IN_MAINTENANCE, ELEVATOR_FONCTIONNEMENT_OK, ELEVATOR_FONCTIONNEMENT_KO } from '@/utils/constantsUtils'
import Alert from '@/components/Stations/Alert'
import moment from 'moment'
import common from '@/services/common'

export default {
  name: 'StationDetail',
  data () {
    return {
      station: {},
      editOpen: false,
      canEdit: false,
      dateDeploy: null,
      daysConstant: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'ferie'],
      STATION_FULL_ACCESSIBILITY,
      STATION_PARTIAL_ACCESSIBILITY,
      STATION_LIMITED_ACCESSIBILITY,
      STATION_NO_ACCESSIBILITY,
      ELEVATOR_IN_SERVICE,
      ELEVATOR_IN_MAINTENANCE,
      ELEVATOR_FONCTIONNEMENT_OK,
      ELEVATOR_FONCTIONNEMENT_KO,
      elevatorOk: false,
      elevatorNok: false,
      noElevator: false,
      noEscalator: false
    }
  },
  mounted () {
    this.getStation().then(() => $(this.$el).foundation())
  },
  methods: {
    getStation: function () {
      return this.$backendConnector.getGareByUic(this.$route.params.codeUic)
        .then(res => {
          // Add some attributes to assistActors for the update component
          res.assistActors.map(this.mapAssistActor)
          this.station = res
          this.dateDeploy = res.deploySoca ? moment(res.deploySoca).format('DD-MM-YYYY') : '-'
          if (!this.station.equipment) {
            return this.station
          }
          const equipmentStatus = common.getStationElevaticStatus(res?.equipment)
          this.station.equipment.elevators = equipmentStatus.elevators
          this.elevatorOk = equipmentStatus.elevatorOk
          this.elevatorNok = equipmentStatus.elevatorNok
          this.noElevator = equipmentStatus.noElevator
          this.noEscalator = equipmentStatus?.noEscalator
          return this.station
        })
        .then(station => {
          this.$store.commit('addStationToHistory', station)
          const canEdit = this.$perms.isUserAllowed('stations.update')
          const canEditAll = this.$perms.isUserAllowed('stations.update.all')
          const canEditSelf = this.$perms.isUserAllowed('stations.update.self')
          if (canEdit && (canEditAll || (
            canEditSelf &&
            (
              this.$store.getters.possibleStations.map(s => s.codeUic).includes(station.codeUic) ||
              (this.$perms.isUserAllowed('stations.agencies') && this.$store.getters.userAgenciesCode.includes(station.agencyCode)) ||
              (this.$perms.isUserAllowed('stations.regions') && this.$store.getters.userRegionCodes.includes(station.region))
            )
          ))) {
            this.canEdit = true
          }
        })
        .catch(error => console.log(error, 'Something went wrong'))
    },
    formatHours: function (value) {
      if (value) {
        const from = value.split('-')[0] === 'null' ? '-' : value.split('-')[0]
        const to = value.split('-')[1] === 'null' ? '-' : value.split('-')[1]
        return this.$t('hours.format', { from: from, to: to })
      }
    },
    updateIsComplete: function () {
      this.editOpen = false
      this.getStation().then(() => $(this.$el).foundation())
    },
    mapAssistActor: function(assistActor) {
      assistActor.daysAgent = {
        mondayAgent: { start: null, end: null },
        tuesdayAgent: { start: null, end: null },
        wednesdayAgent: { start: null, end: null },
        thursdayAgent: { start: null, end: null },
        fridayAgent: { start: null, end: null },
        saturdayAgent: { start: null, end: null },
        sundayAgent: { start: null, end: null },
        ferieAgent: { start: null, end: null },
        mondayAgent2: { start: null, end: null },
        tuesdayAgent2: { start: null, end: null },
        wednesdayAgent2: { start: null, end: null },
        thursdayAgent2: { start: null, end: null },
        fridayAgent2: { start: null, end: null },
        saturdayAgent2: { start: null, end: null },
        sundayAgent2: { start: null, end: null },
        ferieAgent2: { start: null, end: null },
        mondayAgent3: { start: null, end: null },
        tuesdayAgent3: { start: null, end: null },
        wednesdayAgent3: { start: null, end: null },
        thursdayAgent3: { start: null, end: null },
        fridayAgent3: { start: null, end: null },
        saturdayAgent3: { start: null, end: null },
        sundayAgent3: { start: null, end: null },
        ferieAgent3: { start: null, end: null }
      }
      assistActor.hasError = {
        leadMail: false,
        assistMail: false,
        emailAssist: false,
        acteur: false,
      }
      assistActor.localId = Math.floor(Math.random() * 100000000)
    },
    assistActorClosed: function(actor, day) {
      return actor[day + 'AgentClosed']
    }
  },
  computed: {
    updatedDate: function () {
      if (this.station && this.station.updateDate) {
        return moment(this.station.updateDate).format('DD/MM/YYYY')
      }
      return null
    },
    updatedHour: function () {
      if (this.station && this.station.updateDate) {
        return moment(this.station.updateDate).format('HH:mm')
      }
      return null
    }
  },
  components: { StationEdit, PhoneCallLink, EmailLink, UnlinkedIndicator, Alert }
}
</script>
