<template>
  <div id="prestationNewStep3" v-if="account">
    <div class="socaContent socaContentNarrow">
      <div class="prestationCreationTitle">{{$t('prestation.new') }}</div>
      <div class="titleStep">{{$t('prestation.create.info') }}</div>
    </div>
    <div v-if="errorMessage" class="alertBox error">
        {{ errorMessage }}
    </div>
    <div class="mainForm">
      <div class="segmentsRecap">
        <div class="segmentsRecapBackground"></div>
        <div class="socaContent socaContentNarrow">
            <div class="cell segmentsContainer">
                <div class="segmentsNavigation" v-if="segments.length > 1">
                  <span @click="previousSegment" :class="{'segmentsNavigationPrev': true, 'segmentsNavigationActive': (segmentsIndex > 0)}"><svgicon icon="arrow_left"/></span>
                  <span @click="nextSegment" :class="{'segmentsNavigationNext': true, 'segmentsNavigationActive': (segmentsIndex != (segments.length - 1))}"><svgicon icon="arrow_right"/></span>
                </div>
                <div v-if="this.segments.length > 1" class="segmentsDelete button-theme-round-light button-theme-simplify-in-small" @click="deleteSegment">
                  <svgicon icon="trash" class="button-icon"/>
                  <span class="button-text">{{$t('prestation.create.deletesegment') }}</span>
                </div>
                <div v-if="showCreateSegmentButton" class="segmentsCreate button-theme-round-light button-theme-simplify-in-small" @click="createSegment" :class="{'disableNewSegmentButton': disableNewSegmentButton }">
                  <svgicon icon="plus" class="button-icon"/>
                  <span class="button-text">{{$t('prestation.create.addsegment') }}</span>
                </div>
                <div class="segmentsTitle">
                  <div class="segmentsSpotholder" v-if="segments.length > 1"></div>
                  <div class="segmentsTitleText">{{$t('prestation.create.segment') }}&nbsp;
                    <span v-if="segments.length > 1 && segmentsIndex < 9">{{ '0' + (segmentsIndex + 1)}}</span>
                    <span v-if="segmentsIndex >= 9">{{ (segmentsIndex + 1) }}</span>
                  </div>
                  <div class="segmentsIndicators" v-if="segments.length > 1">
                    <span v-for="(segment, index) in segments"
                      :index="index" :key="segment.from.tempId" class="segmentsIndicator" :class="{ 'segmentsIndicatorActive': index == segmentsIndex }">
                    </span>
                  </div>
                </div>
                <div class="grid-x bubbleJourney">
                    <div class="cell small-6 bubbleJourneyPart" :class="{ 'bubbleJourneyActive': segmentPart == 'from', 'soloBubble': disableNewSegmentButton, 'disableFromSegmentButton': onBoardPrestation }" @click="showPrestaFrom">
                      <span class="bubbleJourneyAbbrev" v-if="segments[segmentsIndex]['from'].mention">{{ segments[segmentsIndex]['from'].mention }}</span>
                      <span class="bubbleJourneyText">{{ stationData[segmentsIndex]['from'] ? stationData[segmentsIndex]['from'].name : $t('journey.from') }}</span>
                    </div>
                    <div class="cell small-6 bubbleJourneyPart" :class="{ 'bubbleJourneyActive': segmentPart == 'to' }" @click="showPrestaTo" v-if="!disableNewSegmentButton">
                      <span class="bubbleJourneyAbbrev" v-if="segments[segmentsIndex]['to'].mention">{{ segments[segmentsIndex]['to'].mention }}</span>
                      <span class="bubbleJourneyText">{{ stationData[segmentsIndex]['to'] ? stationData[segmentsIndex]['to'].name : $t('journey.to') }}</span>
                      <span class="bubbleJourneyAbbrev bubbleJourneyFirstToIcon" v-if="segmentsIndex == 0 && segmentPart != 'to' && !(stationData[0]['to'])"><svgicon icon="plus"/></span>
                    </div>
                </div>
            </div>
            <JourneyCard :canEdit='true' :journey="onBoardPrestation || segments[segmentsIndex].journey" :onBoardJourneyData="onBoardJourneyData"
            :stationDataFrom="stationData[segmentsIndex]['from']" @journeyData="journeyData"
            :segmentsIndex="segmentsIndex" :journeyDate='journeyDate'></JourneyCard>
        </div>
      </div>
      <div class="socaContent socaContentNarrow segmentStationContent">
        <form>
            <StationPrestaCard :segmentPart="segmentPart" :segmentsIndex="segmentsIndex"
              :stationData="stationData[segmentsIndex]" :stationPresta="segments[segmentsIndex][segmentPart]"
              @stationPrestaData="stationPrestaData" :journey="segments[segmentsIndex].journey" :canEdit="true"></StationPrestaCard>
        </form>
        <button @click.prevent="next" :disabled="isLoading" class="button-theme nextStepButton">{{$t('prestation.create.next')}}</button>
      </div>
      <div class="segmentsAccount">
        <div class="socaContent socaContentNarrow">
          <div class="infoPanel prestaAccountPanel panelIsCompleted">
            <a v-if="!onBoardPrestation" class="toCompleteButton editButton" @click="openModalAccount"><svgicon icon="pencil"/><span>{{$t('prestation.toComplete')}}</span></a>
            <div class="infoPanelLine botBd infoPanelTitle">{{$t('account.client') }}</div>
            <div class="infoPanelLine grid-x">
              <div class="cell small-6 flexAlignCenter">
                <span class="clientName">{{ account.civility ? $t('common.civility.short.'+$sncfRefs.getCivilityTechnicalNameByLabel(account.civility)) : '' }} {{account.lastname}}</span>
                <span class="clientPhone hide-for-small-only">{{account.phone}}</span>
              </div>
              <div class="cell small-6 flexRowReverse">
                <div class="assists">
                  <PrestaAssistBox :element="account" />
                </div>
                <div class="bags" v-if="!isOnBoard">
                  <span v-tooltip="{
                        content: $t('tooltip.bagage'),
                        trigger: 'hover click',
                      }"><svgicon icon="bag"/></span>
                  <span class="bag">
                    {{parcours.bagage}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="openedModal">
        <AccountForm @closeModal="closeModalAccount" @saveAccount="saveAccount" :accountData="account" :parcoursData="parcours" />
      </div>
    </div>
  </div>
</template>

<script>
import JourneyCard from '@/components/Prestations/Creation/Part/JourneyCard'
import StationPrestaCard from '@/components/Prestations/Creation/Part/StationPrestaCard'
import AccountForm from '@/components/Prestations/Creation/Part/AccountForm'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'

import moment from 'moment'
import validationPresta from '@/services/validatePrestationCreation'
import common from '@/services/common'
import userService from '@/services/userService'
import { NO_ELIGIBLE_ASSISTANCE_MOTIF } from '@/utils/constantsUtils'

export default {
  name: 'PrestationNewStepSegments',
  props: { accountData: Object, parcoursData: Object, onBoardPrestation: Object, contextStationPresta: Object, stepSegmentsSave: null, onBoardJourneyData: Object },
  data () {
    return {
      account: {},
      parcours: {},
      segmentsIndex: 0,
      isOnBoardArrival: false,
      segments: [{
        journey: null,
        from: { tempId: 0, state: this.$sncfRefs.getPrestaStatesByTechnicalName().accepted, autoAccepted: true, segmentPart: 'from' },
        to: { tempId: 1, segmentPart: 'to' }
      }],
      stationData: [{ from: null, to: null }],
      segmentPart: 'from', // from or to
      errorMessage: null,
      openedModal: false,
      disableNewSegmentButton: false,
      isLoading: false
    }
  },
  created () {
    if (this.isOnBoard && this.isOnBoardArrivalPrestation) {
      this.account = this.$store.getters.onBoardAccount
      this.parcours = this.$store.getters.onBoardParcours
      this.contextStationPresta = this.$store.getters.onBoardStationData
      this.segmentPart = 'to'
      this.isOnBoardArrival = false
      this.segments[0]['from'].mention = this.onBoardPrestation.mention
      this.journeyData(this.onBoardPrestation)
    } else {
      this.account = this._.assign({}, this.accountData)
      this.parcours = this._.assign({}, this.parcoursData)
    }
    this.segments[this.segmentsIndex]['from'].presentationTime = moment().format('HH:mm')

    if (this.stepSegmentsSave !== null && this.stepSegmentsSave?.segments.length > 0) {
      this.segments = this.stepSegmentsSave.segments
      this.stationData = this.stepSegmentsSave.stationData
    }

    if (this.contextStationPresta) {
      if (!this.segments[0].from.sfidStation || !this.segments[0].from.codeUic) {
        this.segments[0].from.garePresta = this.contextStationPresta
        this.segments[0].from.codeUic = this.contextStationPresta.codeUic
        this.segments[0].from.sfidStation = this.contextStationPresta.sfid
      }
      if (this.stationData[0].from === null) {
        this.stationData[0].from = this.contextStationPresta
      }
    }

    if (this.segments[0]['from'].mention === null || this.segments[0]['from'].mention === undefined) {
      const canHavePrestation = this.canHavePrestation(this.contextStationPresta, null, null)
      if (!canHavePrestation) {
        this.segments[0]['from'].mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
      } else {
        this.segments[0]['from'].mention = this.$sncfRefs.getMentionsByTechnicalName().departure.sigle
      }
    }
  },
  methods: {
    next () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      if (!this.checkLastSegment(this.segments[this.segments.length - 1])) {
        this.isLoading = false
        return false
      }
      if (!this.isOnBoardArrival && (this.segments[0].from === null || this.segments[0].journey === null)) {
        this.errorMessage = this.$t('prestation.error.noprestation')
        this.$toastService.showToast(this.errorMessage, null, 'error')
      } else {
        // Construction des prestations à partir des segments.
        let prestations = []
        for (let i in this.segments) {
          if (Object.keys(this.segments[i].from).length > 1) {
            const newPresta = this.formatPrestationFromJourney(this._.clone(this.segments[i].journey), this.segments[i].from, this.segments[i].to)
            prestations.push(newPresta)
          }
          if (Object.keys(this.segments[i].to).length > 1 && this.segments[i].to.mention) {
            const newPresta = this.formatPrestationFromJourney(this._.clone(this.segments[i].journey), this.segments[i].to, this.segments[i].from)
            prestations.push(newPresta)
          }
        }

        // Date de présentation obligatoire sur la première presta si spontanée.
        const errorPresentationTime = !validationPresta.checkPresentationTime(prestations[0])
        if (errorPresentationTime && !this.isOnBoard) {
          this.$toastService.showToast(this.$t('prestation.error.presentationTimeRequired'), null, 'error')
          this.isLoading = false
          return false
        }

        validationPresta.checkTripConsistency(prestations)
          .then(() => {
            this.$emit('stepSegmentsOk',
              { segments: this.segments, stationData: this.stationData },
              prestations
            )
          })
          .catch(e => {
            this.$toastService.showToast(e.message, null, 'error')
            console.log('fail')
            this.isLoading = false
          })
      }
    },

    formatPrestationFromJourney (journey, seg, otherSeg) {
      let newPresta = this._.assign(journey, seg)
      if (journey) {
        newPresta.garePrestaUic = seg.codeUic
        newPresta.datePrestation = seg.datePrestation
        if ([this.$sncfRefs.getMentionsByTechnicalName().arrival.sigle, this.$sncfRefs.getMentionsByTechnicalName().transitArrival.sigle, this.$sncfRefs.getMentionsByTechnicalName().none.sigle].includes(newPresta.mention)) {
          if (newPresta.datePrestationHour) {
            newPresta.datePrestation = common.setHoursMinute(newPresta.datePrestationHour, newPresta.datePrestation)
          }
          newPresta.trainDeparture = newPresta.datePrestation
        }
      }
      if (otherSeg) {
        newPresta.gareAutreUic = otherSeg.codeUic
      }
      return newPresta
    },
    canHavePrestation: function (station, prestaTransporter, transportMode) {
      let isMentionNRequired = false
      if (station) {
        isMentionNRequired = this.checkMentionRequirement(station, prestaTransporter, transportMode)
      }

      return !isMentionNRequired
    },
    checkMentionRequirement: function(station, prestaTransporter, transportMode) {
      if (this.isTransporter) {
        return common.isMentionNRequiredForTransporter(station, prestaTransporter, transportMode)
      }

      if (this.isGAndCoOrOther) {
        return common.isMentionNRequiredForOtherUsers(station, prestaTransporter)
      }

      return false
    },
    journeyData (val) {
      // Use this.$set to ensure the reactivity of the changes
      this.$set(this.segments[this.segmentsIndex], 'journey', val)
      if (this.isOnBoard) {
        this.onBoardJourneyData = val
      }
      this.$set(this.segments[this.segmentsIndex], 'journey', val)
      this.$set(this.segments[this.segmentsIndex].journey, 'datePrestation', this.onBoardPrestation ? new Date(val.datePrestation) : val.datePrestation)
      this.$set(this.segments[this.segmentsIndex].journey, 'isNew', false)

      this.$set(this.segments[this.segmentsIndex]['from'], 'datePrestation', val.datePrestation)
      this.$set(this.segments[this.segmentsIndex]['from'], 'datePrestationHour', val.datePrestationHour)
      if (!this.isOnBoard) {
        const fromResult = this.canHavePrestation(this.segments[this.segmentsIndex].from.garePresta, val.transporter, val.transportMode)
        if (!fromResult) {
          this.$set(this.segments[this.segmentsIndex]['from'], 'mention', this.$sncfRefs.getMentionsByTechnicalName().none.sigle)
          if (!this.isFirstPrestaFrom) {
            this.$set(this.segments[this.segmentsIndex]['from'], 'reason', NO_ELIGIBLE_ASSISTANCE_MOTIF)
          }
        }
        if (this.segments[this.segmentsIndex].to.garePresta) {
          const toResult = this.canHavePrestation(this.segments[this.segmentsIndex].to.garePresta, val.transporter)
          if (!toResult) {
            this.$set(this.segments[this.segmentsIndex]['to'], 'mention', this.$sncfRefs.getMentionsByTechnicalName().none.sigle)
            this.$set(this.segments[this.segmentsIndex]['to'], 'reason', NO_ELIGIBLE_ASSISTANCE_MOTIF)
          }
        }
      }
    },
    stationPrestaData (prestaDataIn) {
      this.stationData[this.segmentsIndex][this.segmentPart] = prestaDataIn.garePresta
      this.segments[this.segmentsIndex][this.segmentPart] = prestaDataIn
      if (this.segmentPart === 'from' && this.segmentsIndex === 0) {
        if (this.segments[0]['from'].mention === 'A') {
          this.disableNewSegmentButton = true
          this.segments[0]['to'] = {}
          this.segments[0]['from'].segmentPart = 'to'
        } else {
          this.disableNewSegmentButton = false
          if (!this.segments[0]['to'] || this.segments[0]['to'].segmentPart !== 'to') {
            this.segments[0]['to'] = { tempId: 1, segmentPart: 'to' }
          }
          this.segments[0]['from'].segmentPart = 'from'
        }
      }
      // Autocomplétion du debut de l'étape suivante si existante.
      if (this.segmentPart === 'to' && this.segmentsIndex < (this.segments.length - 1)) {
        let lastStation = this.stationData[this.segmentsIndex]['to']
        this.stationData[(this.segmentsIndex + 1)]['from'] = lastStation
        this.segments[(this.segmentsIndex + 1)]['from'].garePresta = lastStation
        this.segments[(this.segmentsIndex + 1)]['from'].codeUic = lastStation ? lastStation.codeUic : null
        this.segments[(this.segmentsIndex + 1)]['from'].sfidStation = lastStation ? lastStation.sfid : null
      }
    },

    previousSegment () {
      if (this.segmentsIndex > 0) {
        this.segmentsIndex--
      }
    },
    nextSegment () {
      if (this.segmentsIndex < (this.segments.length - 1)) {
        this.segmentsIndex++
      }
    },
    createSegment () {
      if (!this.checkLastSegment(this.segments[this.segments.length - 1])) {
        return false
      }
      let id = this.segments[this.segments.length - 1].to.tempId
      let lastStation = this.stationData[(this.segments.length - 1)]['to']
      let from = {
        tempId: id + 1,
        garePresta: lastStation,
        codeUic: lastStation ? lastStation.codeUic : null,
        sfidStation: lastStation ? lastStation.sfid : null,
        datePrestation: this.segments[this.segments.length - 1].to.datePrestation,
        segmentPart: 'from'
      }
      let to = {
        tempId: id + 2,
        datePrestation: this.segments[this.segments.length - 1].to.datePrestation,
        segmentPart: 'to'
      }
      if (this.segments[this.segmentsIndex]['to'].mention === this.$sncfRefs.getMentionsByTechnicalName().transitArrival.sigle) {
        from.mention = this.$sncfRefs.getMentionsByTechnicalName().transitDeparture.sigle
      }
      let journey = {
        datePrestation: this.segments[this.segments.length - 1].to.datePrestation,
        garantee: this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed,
        origin: 'Front',
        status: 'À réaliser',
        statusTN: 'todo',
        transportMode: this.$sncfRefs.getTransportModesByTechnicalName().train,
        transporter: null,
        trainCar: null,
        trainPlace: null,
        trainNumber: null,
        onAskedTrain: null,
        isNew: true
      }
      this.segments.push({ journey: journey, from: from, to: to })
      this.stationData.push({ from: lastStation, to: null })
      this.segmentsIndex = this.segments.length - 1
      this.segmentPart = 'from'
    },
    deleteSegment () {
      this.segments.splice(this.segmentsIndex, 1)
      this.stationData.splice(this.segmentsIndex, 1)
      this.segmentsIndex = this.segments.length - 1
    },
    checkLastSegment (segment) { // TODO Passer ce check aussi sur liste de prestation pour recap et report
      const from = segment.from
      const to = segment.to
      let ret = ['D', 'DC', 'N'].includes(from.mention) && ['A', 'AC', 'N', undefined].includes(to.mention)
      if (this.segments.length === 1 && this._.isEmpty(this.segments[0].to)) {
        ret = true
      }
      if (from.mention === 'N' && to.mention === 'N') {
        this.$toastService.showToast(this.$t('prestation.create.error.twoNo'), null, 'error')
      }
      if (!ret) {
        this.$toastService.showToast(this.$t('prestation.create.error.wrongOrder'), null, 'error')
      }
      if (!segment.journey || !segment.journey.transporter) {
        this.$toastService.showToast(this.$t('prestation.create.error.noJourney'), null, 'error')
        return false
      }
      if (this.onBoardPrestation && !segment.to.garePresta) {
        this.$toastService.showToast(this.$t('prestation.create.error.noJourney'), null, 'error')
        return false
      }
      return ret
    },
    showPrestaFrom () {
      this.segmentPart = 'from'
    },
    showPrestaTo () {
      this.segmentPart = 'to'
    },

    openModalAccount () {
      this.openedModal = !this.openedModal
    },
    closeModalAccount () {
      this.openedModal = false
    },
    saveAccount (data) {
      this.account = data.account
      this.parcours = data.parcours
      this.$emit('saveAccount', data)
    }
  },
  computed: {
    journeyDate: function () {
      return this.segments[0].journey ? this.segments[0].journey.datePrestation : null
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    isOnBoardArrivalPrestation() {
      return this.$store.getters.isOnBoardArrival
    },
    showCreateSegmentButton() {
      return !this.isOnBoard || this.onBoardPrestation !== null
    },
    isTransporter() {
      return userService.isTransporter()
    },
    isGAndCoOrOther() {
      return !userService.isTransporter() && !userService.isOnBoardCrew()
    },
    isFirstPrestaFrom: function () {
      return this.segmentPart === 'from' && this.segmentsIndex === 0
    },
  },
  components: { JourneyCard, StationPrestaCard, AccountForm, PrestaAssistBox }
}
</script>
