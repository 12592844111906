<template>
<div class="infoPanel trainStation" v-bind:class="{ 'panelIsCompleted': stationData[segmentPart] || stationPresta }">
    <a v-if='canEdit' @click="openModal" class="toCompleteButton editButton">
      <svgicon icon="pencil"/><span>{{$t('prestation.toComplete')}}</span>
    </a>
    <div class="infoPanelLine botBd infoPanelTitle">
      <span v-if="segmentPart === 'from'">{{$t('prestation.station.start') }}</span>
      <span v-if="segmentPart === 'to'">{{$t('prestation.station.stop') }}</span>
      <span v-if="segmentPart === 'mine'">{{$t('prestation.station.mine') }}</span>
    </div>
    <div v-if="stationData[segmentPart]">
      <div class="infoPanelLine botBd journeyStation">{{stationData[segmentPart].name}}</div>
      <div class="infoPanelLine botBd pmrPanel stationDetail">
        <div class="resume resumeStationDta">
          <div class="resumeColumns">
          <div class="resumeColumn">
            <div class="pmrTitle">{{ $t('prestation.create.pmrService') }}</div>
            <div class="assistActor" v-for="assistActor in stationData[segmentPart].assistActors" :key="assistActor.herokuExternalId">
              <div class="responsible">
                <span class="responsibleAssist">{{ $t('station.resp')}}: {{ $sncfRefs.getAssistResponsible(assistActor.gcActorCode !== null) }}</span>
              </div>
              <div class="responsible">
                <span class="incumbent">{{ $t('station.incumbent')}}: {{ assistActor.gcActorCode !== null ? assistActor.gcActor.name : assistActor.transporter.name }}</span>
              </div>
              <Hours class="pmrDesc" :assistActor="assistActor" />
              <PmrIcons :assistActor="assistActor"/>
            </div>
            <div v-if="stationData[segmentPart].assistActors.length === 0" class="pmrIcons">
              <PmrIcons :assistActor="{}"/>
            </div>
          </div>
          <div class="resumeColumn">
            <div class="resumeLine" v-if="stationData[segmentPart].spontaneous">
              <div class="spontaneousIcon" ><svgicon icon="thunder"/></div>
              <span>{{ $t('station.spontaneous') }}</span>
            </div>
            <div class="resumeLine"  v-if="stationData[segmentPart].garanteed">
              <div class="garanteedIcon"><svgicon icon="star" /></div>
              <span>{{ $t('station.garanteed') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].isPMR">
              <div class="pmrIcon">PMR</div>
              <span>{{ $t('station.pmr') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].soca">
              <div class="socaIcon">SOCA</div>
              <span>{{ $t('station.useSoca') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].accessibilityLevel === STATION_FULL_ACCESSIBILITY">
              <svgicon class="icons fullAccessibilityStation" icon="accessible_for_all"/>
              <span>{{ $t('station.fullAccessibility') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].accessibilityLevel === STATION_PARTIAL_ACCESSIBILITY">
              <svgicon class="icons partialAccessibilityStation" icon="accessible"/>
              <span>{{ $t('station.partialAccessibility') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].accessibilityLevel === STATION_LIMITED_ACCESSIBILITY">
              <svgicon class="icons limitedAccessibilityStation" icon="barely_accessible"/>
              <span>{{ $t('station.limitedAccessibility') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].accessibilityLevel === STATION_NO_ACCESSIBILITY || stationData[segmentPart].accessibilityLevel === null">
            <svgicon class="icons noAccessibilityStation" icon="inaccessible"/>
              <span>{{ $t('station.noAccessibility') }}</span>
              </div>
            <div class="resumeLine" v-if="!stationData[segmentPart].equipment">
              <svgicon class="icons elevatorNok" icon="elevator"/>
              <span>{{ $t('station.unavailableElevatorData') }}</span>
            </div>
            <div class="resumeLine" v-if="elevatorOk">
              <svgicon class="icons elevator" icon="elevator"/>
              <span>{{ $t('station.elevatorOk') }}</span>
            </div>
            <div class="resumeLine" v-if="elevatorNok">
              <svgicon class="icons elevatorNok" icon="elevator"/>
              <span>{{ $t('station.elevatorNok') }}</span>
            </div>
            <div class="resumeLine" v-if="noElevator">
              <svgicon class="icons elevator" icon="elevator"/>
              <span>{{ $t('station.noElevator') }}</span>
            </div>
            <div class="resumeLine" v-if="stationData[segmentPart].compatibleHardware">
              <svgicon class="icons hardware" icon="hardware"/>
              <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ stationData[segmentPart].compatibleHardware }}</span>
            </div>
            <div class="resumeLine" v-else>
              <svgicon class="icons hardware" icon="hardware"/>
              <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ $t('station.noComptibleHardware') }}</span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div v-if="stationPresta">
      <div class="infoPanelLine botBd position-relative">
        <div class="fieldLabel">{{$t('journey.details')}}</div>
        <div class="journeyDirection">{{ stationPresta.mention ? $sncfRefs.getMentionsBySigle(stationPresta.mention) : $t('journey.to') }}</div>
        <div>
          <span class="prestaHour">{{ stationPrestaHourLbl }}</span>
          <span class="prestaDate"> {{ stationPrestaDateLbl }}</span>
        </div>
        <div class="shortMention" v-if="stationPresta.mention">{{stationPresta.mention}}</div>
      </div>
      <div class="infoPanelLine botBd" v-if="stationPresta.manager">
        <div class="fieldLabel">{{$t('prestation.station.manager')}}</div>
        <div class="journeyDirection">{{ stationPresta.manager }}</div>
        <div>
          <span class="prestaHour">{{ presentationTimeLbl }}</span>
        </div>
      </div>
      <div class="infoPanelLine botBd" v-if="stationPresta.onboardingDocStatus">
        <div class="onboarding">{{$t('prestation.solbord.status')}}</div>
        <div class="onboardingDirection">{{ $sncfRefs.getOnboardingDocStatusByTechnicalName()[stationPresta.onboardingDocStatus] }}</div>
        <div v-if='stationPresta.onboardingDocComment'>
          <div class="onboarding">{{$t('prestation.solbord.comment')}}</div>
          <div class="onboardingDirection">{{ stationPresta.onboardingDocComment }}</div>
        </div>
      </div>
      <div class="infoPanelLine botBd" v-if="stationPresta.commentary">
        <div class="fieldLabel">{{$t('prestation.station.commentary')}}</div>
        <div class="commentary">{{ stationPresta.commentary }}</div>
      </div>
      <div class="infoPanelLine botBd" v-if="hasComments">
        <div class="fieldLabel">{{$t('prestation.transporter.commentary')}}</div>
        <div class="commentary" v-if="stationPresta.commentaryTransporter">{{ stationPresta.commentaryTransporter }}</div>
        <div class="commentary" v-if="stationPresta.commentarySecondaryTransporter">{{ stationPresta.commentarySecondaryTransporter }}</div>
        <div class="commentary" v-if="stationPresta.commentaryPfu">{{ $t(`prestation.pfuComment.${stationPresta.commentaryPfu}`) }}</div>
        <div class="commentary" v-if="stationPresta.commentaryPfuOther">{{ stationPresta.commentaryPfuOther }}</div>
      </div>
      <div class="infoPanelLine botBd" v-if="stationPresta.commentaryQualif">
        <div class="fieldLabel">{{$t('prestation.station.commentaryqualif')}}</div>
        <div class="commentary">{{ stationPresta.commentaryQualif }}</div>
      </div>
      <div class="infoPanelLine botBd" v-if="stationPresta.commentaryQualifOther">
        <div class="fieldLabel">{{$t('prestation.transporter.commentaryqualifother')}}</div>
        <div class="commentary">{{ stationPresta.commentaryQualifOther }}</div>
      </div>
    </div>
    <div v-if="openedModal">
      <StationPrestaForm :parentStationData="stationData[segmentPart]" :segmentsIndex="segmentsIndex" :segmentPart="segmentPart"
        :formData="stationPresta" @closeStation="closeModal" @saveStation="saveStation" :journey="journey" />
    </div>
</div>
</template>

<script>
import StationPrestaForm from '@/components/Prestations/Creation/Part/StationPrestaForm'
import Hours from '@/components/Stations/Hours'
import PmrIcons from '@/components/Prestations/Creation/Part/PmrIcons'
import { STATION_FULL_ACCESSIBILITY, STATION_PARTIAL_ACCESSIBILITY, STATION_LIMITED_ACCESSIBILITY, STATION_NO_ACCESSIBILITY } from '@/utils/constantsUtils'

import moment from 'moment'
import common from '@/services/common'

export default {
  name: 'StationPrestaCard',
  props: { stationPresta: Object, segmentPart: String, stationData: Object, segmentsIndex: Number, journey: Object, canEdit: Boolean },
  data () {
    return {
      STATION_FULL_ACCESSIBILITY,
      STATION_PARTIAL_ACCESSIBILITY,
      STATION_LIMITED_ACCESSIBILITY,
      STATION_NO_ACCESSIBILITY,
      elevatorOk: false,
      elevatorNok: false,
      noElevator: false,
      openedModal: false
    }
  },
  mounted () {
    if (this.canEdit === undefined) {
      this.canEdit = true
    }
  },
  watch: {
    stationData: {
      immediate: true,
      deep: true,
      handler(newStation) {
        this.updateElevatorStatus(newStation);
      }
    }
  },
  methods: {
    saveStation (prestaDataIn) {
      this.$emit('stationPrestaData', prestaDataIn)
    },
    openModal () {
      this.openedModal = true
    },
    closeModal () {
      this.openedModal = false
    },
    updateElevatorStatus (stationData) {
      if (!stationData[this.segmentPart]?.equipment) {
        return
      }
      const equipmentStatus = common.getStationElevaticStatus(stationData[this.segmentPart]?.equipment)
      this.elevatorOk = equipmentStatus.elevatorOk
      this.elevatorNok = equipmentStatus.elevatorNok
      this.noElevator = equipmentStatus.noElevator
    },
  },
  computed: {
    stationPrestaDateLbl: function () {
      if (this.stationPresta) {
        return moment(this.stationPresta.datePrestation).locale(this.$i18n.locale).format('Do MMMM YYYY')
      }
      return null
    },
    stationPrestaHourLbl: function () {
      if (this.stationPresta) {
        return moment(this.stationPresta.datePrestation).locale(this.$i18n.locale).format('HH:mm')
      }
      return null
    },
    presentationTimeLbl: function () {
      if (this.stationPresta && this.stationPresta.presentationTime) {
        const date = common.setHoursMinute(common.getTimezonedHour(this.stationPresta.presentationTime), this.stationPresta.datePrestation)
        return moment(date).locale(this.$i18n.locale).format('HH:mm - Do MMMM YYYY')
      }
      return null
    },
    hasComments: function() {
      return this.stationPresta.commentaryTransporter || this.stationPresta.commentarySecondaryTransporter || this.stationPresta.commentaryPfu || this.stationPresta.commentaryPfuOther
    }
  },
  components: { StationPrestaForm, Hours, PmrIcons }
}
</script>
