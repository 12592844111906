import i18n from '@/plugins/vue-i18n'
import moment from 'moment'
import backendConnector from '@/services/backendConnector'

export default {
  fetchPivDataByTrainNumber (trainNumber, trainDeparture, codeUic) {
    return new Promise(function (resolve, reject) {
      backendConnector.pivByNumber(trainNumber, moment(trainDeparture).format('YYYY-MM-DD'), codeUic)
        .then(pivResults => {
          if (pivResults.passages && pivResults.passages.length > 0) {
            resolve(pivResults)
          } else {
            reject(new Error(i18n.t('prestation.trajet.nodatafrompiv', { date: moment(trainDeparture).format('DD/MM/YYYY') })))
          }
        })
        .catch(err => {
          console.log('error fetching piv data: ' + err)
          reject(new Error(i18n.t('prestation.trajet.nodatafrompiv', { date: moment(trainDeparture).format('DD/MM/YYYY') })))
        })
    })
  },
  fetchPivDataOnBoard(trainNumber, currentDate) {
    return new Promise(function (resolve, reject) {
      backendConnector.pivByTrainNumber(trainNumber, moment(currentDate).format('YYYY-MM-DD'))
        .then(pivResults => {
          if (pivResults.onBoardUic.length > 0) {
            resolve(pivResults)
          } else {
            reject(new Error(i18n.t('prestation.trajet.nodatafrompiv', { date: moment(currentDate).format('DD/MM/YYYY') })))
          }
        })
        .catch(err => {
          console.log('error fetching piv data: ' + err)
          reject(new Error(i18n.t('prestation.trajet.nodatafrompiv', { date: moment(currentDate).format('DD/MM/YYYY') })))
        })
    })
  },
  fetchPivDataByMissionCode (missionCode, trainDeparture, codeUic) {
    return new Promise(function (resolve, reject) {
      backendConnector.pivByUic(codeUic, moment(trainDeparture).format('YYYY-MM-DD'), missionCode)
        .then(pivResults => {
          if (pivResults.passages && pivResults.passages.length > 0) {
            resolve(pivResults)
          } else {
            reject(new Error(i18n.t('prestation.trajet.nodatafrompivmissioncode', { date: moment(trainDeparture).format('DD/MM/YYYY') })))
          }
        })
        .catch(err => {
          reject(new Error(i18n.t('prestation.trajet.nodatafrompivmissioncode', { date: moment(trainDeparture).format('DD/MM/YYYY') })))
          console.log('error fetching piv data: ' + err)
        })
    })
  },
  fetchPivStationsFromStops: function (stops) {
    return new Promise(function (resolve, reject) {
      let stationsWithPivData = []
      if (stops && stops.constructor === Array) {
        backendConnector.getGaresByUic(stops.reduce(function (prev, curr) {
          return [...prev, curr.codeUic]
        }, []))
          .then(gares => {
            for (var i in gares) {
              let gare = gares[i]
              if (gare.soca) { //  si gare utilisable
                for (var j in stops) {
                  if (stops[j].codeUic === gare.codeUic) {
                    let stationName = gare.name
                    gare = Object.assign(gare, stops[j])
                    gare.name = stationName
                    gare.fromPiv = true
                    stationsWithPivData.push(gare)
                    break
                  }
                }
              }
            }
            stationsWithPivData.sort(function (a, b) { return new Date(a.arrivalTime) - new Date(b.arrivalTime) })
            resolve(stationsWithPivData)
          })
      } else {
        reject(new Error('Wrong stops param'))
      }
    })
  }
}
