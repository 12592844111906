<template>
  <socamodal class="modalJourney modalStationContainer" :title="$t('prestation.segmentinfo')" @close="closeTrajet" @submit="validate">
    <template v-slot:header>
      <div class="modalHeaderInfo hide-for-small-only">{{ $t("common.field.mandatoryFields") }}</div>
    </template>
    <div class="modalFormInTitle">{{ $t("prestation.station.stationInfo") }}</div>
    <div class="stationBlock">
      <div class="stationSearchSelect" v-if="canChangeStation">
        <Multiselect
          v-model="selectedStation"
          :options="stationsList"
          :searchable="true"
          :internal-search="false"
          :options-limit="100"
          @search-change="debouncedSearchStation"
          @input="selectStation"
          label="name"
          track-by="codeUic"
          :placeholder="`${$t('prestation.station.typeToSearch')}`"
          deselectLabel=""
          selectLabel=""
          selectedLabel=""
        >
          <template slot="placeholder">{{ $t("prestation.station.chooseStation") }}</template>
          <template slot="noOptions">{{ $t("prestation.station.searchNoResults") }}</template>
          <template slot="noResult">{{ $t("prestation.station.searchNoResults") }}</template>
          <template slot="caret"><svgicon icon="arrow" class="caret" /></template>
          <template slot="option" slot-scope="props">
            <div class="stationOption">
              <span class="stationOptionName">{{ props.option.name }}</span>
              <span v-if="props.option.fromPiv && (props.option.arrivalTime || props.option.departureTime)" class="pivInfo">
                <span v-if="props.option.arrivalTime">{{ props.option.arrivalTime | hour }}</span>
                <svgicon icon="train" />
                <span v-if="props.option.departureTime">{{ props.option.departureTime | hour }}</span>
              </span>
            </div>
          </template>
          <template slot="singleLabel" slot-scope="value">
            <span>{{ $t("prestation.station.stationOfX", { station: value.option.name }) }}</span>
          </template>
        </Multiselect>
        <small v-if="validation.hasError('prestation.garePrestaUic')" class="error">{{ $t("error.field.mandatory") }}</small>
      </div>
      <div class="stationBlockName" v-if="!canChangeStation">{{ $t("prestation.station.stationOfX", { station: selectedStation.name }) }}</div>
      <br />
    </div>

    <div class="content-block grid-x grid-margin-x">
      <div class="cell small-12 medium-6">
        <StationInfo :station="selectedStation"></StationInfo>
      </div>
      <div class="cell small-12 medium-6">
        <div class="formElement">
          <label>{{ $t("prestation.station.mention") }}</label>
          <select v-model="prestation.mention">
            <option v-for="mention in mentions" :value="mention.sigle" :key="mention.sigle">{{ mention.label }}</option>
          </select>
          <small v-if="validation.hasError('prestation.mention')" class="error">{{ $t("error.field.mandatory") }}</small>
        </div>
        <div class="formElement">
          <label>
            {{ $t("common.date") }}
            <small v-if="datePrestaError" class="error">{{ $t("error.field.mandatory") }}</small>
          </label>
          <Datepicker :available-dates="availableDates" v-model="prestation.datePrestation"></Datepicker>
        </div>
        <div class="formElement">
          <label>
            {{ $t("common.hour") }}
            <small v-if="datePrestaHourError" class="error">{{ $t("error.field.mandatory") }}</small>
          </label>
          <select v-model="prestation.datePrestationHour" v-if="passages.length > 0" @change="passageHourChanged()">
            <option v-for="option in passages" :key="option.hour" :value="option.hour">{{ option.isArrival ? $t("common.arrival") : $t("common.departure") }}&nbsp;-&nbsp;{{ option.hour }}</option>
          </select>
          <Timepicker @change="updateHour()" v-model="prestation.datePrestationHour" v-if="passages.length === 0" />
        </div>

        <!-- TRAJET -->
        <div class="modalFormInTitle">{{ $t("prestation.trajet.trajetInfo") }}</div>
        <div class="formElement position-relative">
          <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
          <label>{{ $t("prestation.trajet.trainnumber") }}</label>
          <input type="text" :placeholder="`${$t('prestation.trajet.trainnumberplaceholder')}`" v-model="prestation.trainNumber" v-debounce="fetchPivDataTrainNumber" />
          <small v-if="validation.hasError('prestation.trainNumber')" class="error">{{ $t("error.field.mandatory") }}</small>
        </div>
        <div class="formElement position-relative">
          <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
          <label>{{ $t("prestation.trajet.missioncode") }}</label>
          <input type="text" :placeholder="`${$t('prestation.trajet.missioncodeplaceholder')}`" v-model="prestation.missionCode" v-debounce="fetchPivDataMissionCode" />
          <small v-if="validation.hasError('prestation.missionCode')" class="error">{{ validation.firstError("prestation.missionCode") }}</small>
        </div>
        <div class="formElement">
          <label>{{ $t("prestation.trajet.transporter") }}</label>
          <select v-model="prestation.transporter">
            <option v-for="transporter in transporters" :value="transporter.code" :key="transporter.code">{{ transporter.name }}</option>
          </select>
          <small v-if="validation.hasError('prestation.transporter')" class="error">{{ $t("error.field.mandatory") }}</small>
        </div>
        <div class="formElement" :class="{ 'hide-for-small-only': !showServiceField }">
          <label>{{ $t("prestation.trajet.service") }}</label>
          <select v-model="prestation.service" :disabled="!showServiceField">
            <option v-for="service in services" :value="service.code" :key="service.code">{{ service.code }}</option>
          </select>
          <small v-if="validation.hasError('prestation.service')" class="error">{{ $t("error.field.mandatory") }}</small>
        </div>
        <div class="formElement">
          <label>{{ $t("prestation.trajet.transportMode") }}</label>
          <select v-model="prestation.transportMode">
            <option v-for="transportMode in transportModes" :value="transportMode" :key="transportMode">
              {{ $t("common.transportMode." + $sncfRefs.getTransportModeTechnicalNameByLabel(transportMode)) }}
            </option>
          </select>
          <small v-if="validation.hasError('prestation.transportMode')" class="error">{{ $t("error.field.mandatory") }}</small>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-6 formElement">
            <label>{{ $t("prestation.trajet.traincar") }}<span v-if="mandatoryCarAndPlace">*</span></label>
            <input type="text" :placeholder="`${$t('prestation.trajet.traincarplaceholder')}`" v-model="prestation.trainCar" />
            <small v-if="validation.hasError('prestation.trainCar')" class="error">{{ validation.firstError("prestation.trainCar") }}</small>
          </div>
          <div class="cell small-6 formElement">
            <label>{{ $t("prestation.trajet.trainplace") }}<span v-if="mandatoryCarAndPlace">*</span></label>
            <input type="text" :placeholder="`${$t('prestation.trajet.trainplaceplaceholder')}`" v-model="prestation.trainPlace" />
            <small v-if="validation.hasError('prestation.trainPlace')" class="error">{{ validation.firstError("prestation.trainPlace") }}</small>
          </div>
        </div>
        <div class="grid-x grid-margin-x" v-if="garanteedOnly">
          <div class="formElement transporterDateAsked cell small-6">
            <label>
              {{ $t("prestation.transporter.dateAsked") }}
              <small v-if="validation.hasError('prestation.dateAsked')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <Datepicker v-model="prestation.dateAsked"></Datepicker>
          </div>
          <div class="formElement transporterHourAsked cell small-6">
            <label>
              {{ $t("prestation.transporter.hourAsked") }}
              <small v-if="validation.hasError('prestation.hourAsked')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <Timepicker v-model="prestation.hourAsked" />
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-6" v-if="garanteedOnly">
            <br class="hide-for-small-only" />
            <small v-if="errorGarantee" class="error">{{ $t("error.field.garantee") }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="mandatoryElementInfo show-for-small-only">{{ $t("common.field.mandatoryFields") }}</div>
  </socamodal>
</template>

<script>
import moment from 'moment'
import validationPrestaCreation from '@/services/validatePrestationCreation'
import common from '@/services/common'
import pivService from '@/services/piv'
import userService from '@/services/userService'
import StationInfo from '@/components/Stations/Info'
import Datepicker from '@/components/Common/Datepicker'
import Timepicker from '@/components/Common/Timepicker'
import Multiselect from 'vue-multiselect/src/Multiselect'
import SimpleVueValidation from 'simple-vue-validator'
import { debounce } from 'simple-vue-validator/src/utils'
import { SPONTANE, NO_ELIGIBLE_ASSISTANCE_MOTIF } from '@/utils/constantsUtils'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'RecapSegmentForm',
  props: { prestationData: Object },
  data () {
    return {
      prestation: null,
      stationsSearchResult: [],
      selectedStation: null,
      mentions: [],
      transporters: [],
      transportModes: [],
      services: [],
      availableDates: {
        to: null,
        from: null
      },
      showServiceField: false,
      passages: [],
      isLoadingPiv: false,
      pivSearchTrainNumber: null,
      pivSearchMissionCode: null,
      errorGarantee: false,
      pageLoaded: false,
      datePrestaError: false,
      datePrestaHourError: false,
      stationsFromPiv: []
    }
  },
  created () {
    this.debouncedSearchStation = debounce((query) => this.asyncStationSearch(query), 500)
    this.prestation = this.prestationData
    this.prestation.datePrestationHour = moment(this.prestation.datePrestation)
      .locale(this.$i18n.locale)
      .format('HH:mm')
    this.mentions = this.$sncfRefs.getMentions()
    this.transporters = this.$sncfRefs.getTransportersModelsForUpsert()
    this.loadServices()
    this.fetchStationData(this.prestation.garePrestaUic)
    this.transportModes = this.$sncfRefs.getTransportModes()
    this.selectedStation = this.prestation.garePresta
    this.setAvailableDates()
    const self = this
    this.$nextTick(() => {
      self.pageLoaded = true
    })

    // init stations from piv
    if (this.prestation && this.prestation.nextStops) {
      let self = this
      pivService.fetchPivStationsFromStops(this.prestation.nextStops).then(function (stationsWithPiv) {
        self.stationsFromPiv = stationsWithPiv
      })
    }
  },
  methods: {
    loadServices () {
      this.services = this.$sncfRefs.getServicesModelsForUpsert()
      this.showServiceField = true
      if (!this.$perms.isUserAllowed('presta.creation.garanteed')) {
        this.services = this.services.find((s) => s.code === SPONTANE)
        this.trajet.service = SPONTANE
        this.showServiceField = false
      }
    },
    setAvailableDates () {
      if (this.journeyDate && this.segmentsIndex !== 0) {
        this.availableDates.start = new Date(this.journeyDate)
      } else {
        this.availableDates.start = new Date()
      }

      if (this.journeyDate && this.segmentsIndex !== 0) {
        this.availableDates.end = moment(new Date(this.journey.datePrestation))
          .add('1', 'days')
          .toDate()
      } else if (!this.$perms.isUserAllowed('presta.creation.date.end.any')) {
        this.availableDates.end = moment(new Date())
          .add('1', 'days')
          .toDate()
      }
    },
    fetchPivDataTrainNumber () {
      let currentTrainNumber = this.prestation.trainNumber
      if (currentTrainNumber && currentTrainNumber !== this.pivSearchTrainNumber && currentTrainNumber.length > 3) {
        // search by train number
        this.passages = []
        this.isLoadingPiv = true
        this.pivSearchTrainNumber = currentTrainNumber
        pivService
          .fetchPivDataByTrainNumber(currentTrainNumber, this.prestation.datePrestation, this.selectedStation.codeUic)
          .then((pivData) => {
            if (currentTrainNumber === this.pivSearchTrainNumber) {
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpiv'), this.$el, 'info')
                this.onFetchPivPassage(pivData)
                if (pivData.passages[0].missionCode) {
                  this.prestation.missionCode = pivData.passages[0].missionCode
                }
              }
            }
          })
          .catch((err) => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    fetchPivDataMissionCode () {
      let currentMissionCode = this.prestation.missionCode
      if (currentMissionCode && currentMissionCode !== this.pivSearchMissionCode && currentMissionCode.length > 2) {
        // search by code mission
        this.passages = []
        this.isLoadingPiv = true
        this.pivSearchMissionCode = currentMissionCode
        pivService
          .fetchPivDataByMissionCode(currentMissionCode, this.prestation.datePrestation, this.selectedStation.codeUic)
          .then((pivData) => {
            if (currentMissionCode === this.pivSearchMissionCode) {
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpivmissioncode'), this.$el, 'info')
                this.onFetchPivPassage(pivData)
                this.prestation.trainNumber = pivData.passages[0].trainNumber
              }
            }
          })
          .catch((err) => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    onFetchPivPassage (pivData) {
      let passage = pivData.passages[0]
      this.prestation.datePrestationHour = passage.hour
      this.prestation.transporter = passage.transporter
      for (let i in pivData.passages) {
        passage = pivData.passages[i]
        // on duplique les passages par arrivée et départ de la gare
        if (passage.arrHour && passage.arrHourDateTime) {
          let passageCopyArr = this._.cloneDeep(passage)
          passageCopyArr.hour = passage.arrHour
          passageCopyArr.hourDateTime = passage.arrHourDateTime
          passageCopyArr.isArrival = true
          this.passages.push(passageCopyArr)
        }
        if (passage.depHour && passage.depHourDateTime) {
          let passageCopyDep = this._.cloneDeep(passage)
          passageCopyDep.hour = passage.depHour
          passageCopyDep.hourDateTime = passage.depHourDateTime
          passageCopyDep.isArrival = false
          this.passages.push(passageCopyDep)
        }
      }
    },
    passageHourChanged () {
      for (var i in this.passages) {
        let passage = this.passages[i]
        if (passage.hour === this.prestation.datePrestationHour) {
          this.prestation.transporter = passage.transporter
          this.prestation.trainNumber = passage.trainNumber
          if (passage.missionCode) {
            this.prestation.missionCode = passage.missionCode
          }
        }
      }
      this.updateHour()
    },
    closeTrajet () {
      if (this.datePrestaError === true || this.datePrestaHourError === true) {
        return false
      }
      const hour = this.prestation.datePrestationHour.split(':')[0]
      const minute = this.prestation.datePrestationHour.split(':')[1]
      this.prestation.datePrestation = moment(this.prestation.datePrestation)
        .set({ hour: hour, minute: minute })
        .toDate()
      this.$emit('closeTrajet')
    },
    updateHour () {
      this.prestation.datePrestation = common.setHoursMinute(this.prestation.datePrestationHour, this.prestation.datePrestation)
      this.prestation.trainDeparture = this.prestation.datePrestation
    },
    updateHourAsked () {
      this.prestation.dateAsked = common.setHoursMinute(this.prestation.hourAsked, this.prestation.dateAsked)
    },
    validate () {
      const self = this
      this.$validate().then(function (success) {
        if (success && !self.errorGarantee) {
          for (const passI in self.passages) {
            if (self.passages[passI].hour === self.prestation.datePrestationHour) {
              self.prestation.nextStops = self.passages[passI].nexts
            }
          }
          self.$emit('savePrestation', self.prestation)
          self.closeTrajet()
        }
      })
    },
    selectStation (valSelected) {
      if (valSelected !== null) {
        this.prestation.garePresta = valSelected
        this.prestation.codeUic = valSelected.codeUic
        this.prestation.sfidStation = valSelected.sfid
        if (valSelected.fromPiv) {
          this.prestation.datePrestation = moment(valSelected.arrivalTime).toDate()
          this.prestation.datePrestationHour = moment(valSelected.arrivalTime).format('HH:mm')
        }
        if (!this.canHavePrestation) {
          this.prestation.mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
          this.prestation.reason = NO_ELIGIBLE_ASSISTANCE_MOTIF
          this.mentions = [this.$sncfRefs.getMentionsByTechnicalName().none]
        } else {
          this.mentions = this.$sncfRefs.getMentions()
        }
      } else {
        this.prestation.codeUic = null
        this.prestation.sfidStation = null
        this.prestation.garePresta = null
      }
    },
    fetchStationData (codeUic) {
      return this.$backendConnector.getGareByUic(codeUic).then((results) => {
        this.prestation.garePresta = results
        this.prestation.garePrestaUic = results.codeUic
        this.selectedStation = results
      })
    },
    asyncStationSearch (query) {
      if (query.length > 0) {
        this.$backendConnector.searchStations(query).then((results) => {
          this.stationsSearchResult = results.map((v) => ({ ...v, fromPiv: false }))
          if (!this.$perms.isUserAllowed('presta.creation.garanteed')) {
            this.stationsSearchResult = this.stationsSearchResult.filters((g) => g.spontaneous)
          }
        })
      } else {
        this.stationsSearchResult = []
      }
    },
    validateGarantee () {
      if (this.garanteedOnly) {
        const isGarantee = validationPrestaCreation.garantee(this.prestation)
        if (
          (isGarantee && this.prestation.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed) ||
          this.prestation.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed
        ) {
          this.errorGarantee = false
        } else {
          this.errorGarantee = true
        }
      }
    },
    checkDate () {
      this.datePrestaError = (this.prestation.datePrestation === null)
      this.datePrestaHourError = (this.prestation.datePrestationHour === null)
      return (this.datePrestaError && this.datePrestaHourError)
    },
    buildDatePrestation (value) {
      if (this.prestation.datePrestationHour) {
        this.prestation.datePrestation = common.setHoursMinute(this.prestation.datePrestationHour, value)
      } else {
        this.prestation.datePrestation = value
      }
    }
  },
  computed: {
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      if (this.stationsFromPiv) {
        return this.stationsFromPiv
      }
      return []
    },
    canChangeStation: function () {
      return this.prestationData.mention !== this.$sncfRefs.getMentionsByTechnicalName().departure.sigle
    },
    garanteedOnly: function () {
      // Transporter
      return this.$perms.isUserAllowed('presta.creation.garanteed.only')
    },
    garanteedField: function () {
      // ADMINNAT AU ADMIN USER
      return this.$perms.isUserAllowed('presta.management')
    },
    canHavePrestation: function () {
      // Cas d'une prestation taxi à l'arrivée
      const isTaxiArrival = this.prestation && this.prestation.transportMode === 'Taxi' && this.prestation.segmentPart === 'to'
      if (isTaxiArrival) {
        return false
      }

      let isMentionNRequired = false
      if (this.selectedStation) {
        isMentionNRequired = this.checkMentionRequirement
      }

      return !isMentionNRequired
    },

    checkMentionRequirement: function() {
      if (this.isTransporter) {
        return common.isMentionNRequiredForTransporter(this.selectedStation, null, null)
      }

      if (this.isGAndCoOrOther) {
        return common.isMentionNRequiredForOtherUsers(this.selectedStation, null)
      }

      return false
    },
    mandatoryCarAndPlace() {
      if (this.$store.getters.userRole === 'TRANSPORTER') {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnGarenteed().includes(this.prestation.transporter)
      } else {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous().includes(this.prestation.transporter)
      }
    },
    isTransporter() {
      return userService.isTransporter()
    },
    isGAndCoOrOther() {
      return !userService.isTransporter() && !userService.isOnBoardCrew()
    }
  },
  watch: {
    // when putting all thos fields with a custom validator, the error message are broken so I had to addto watchers
    'prestation.dateAsked': function (val) { this.validateGarantee() },
    'prestation.hourAsked': function (val) {
      if (this.prestation.hourAsked) {
        this.prestation.dateAsked = common.setHoursMinute(this.prestation.hourAsked, this.prestation.dateAsked)
      }
    },
    'prestation.garantee': function (val) { this.validateGarantee() },
    'prestation.service': function (newVal, oldVal) {
      this.validateGarantee()
    },
    'prestation.datePrestation': function (val) {
      this.validateGarantee()
      this.checkDate()
    },
    'prestation.datePrestationHour': function (val) {
      if (this.prestation.datePrestationHour) {
        this.prestation.datePrestation = common.setHoursMinute(this.prestation.datePrestationHour, this.prestation.datePrestation)
      }
      this.checkDate()
    },
    'prestation.transporter': function (newVal, oldVal) {
      this.validateGarantee()
    }
  },
  validators: {
    'prestation.trainNumber': function (value) {
      if (!this.prestation.missionCode) {
        return Validator.value(value).required()
      }
    },
    'prestation.missionCode': function (value) {
      if (!this.prestation.trainNumber) {
        return Validator.value(value)
          .required()
          .lengthBetween(3, 15)
      }
    },
    'prestation.transporter': function (value) {
      return Validator.value(value).required()
    },
    'prestation.service': function (value) {
      return Validator.value(value).required()
    },
    'prestation.transportMode': function (value) {
      return Validator.value(value).required()
    },
    'prestation.trainCar': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value).required().maxLength(30)
      } else {
        return Validator.value(value).maxLength(30)
      }
    },
    'prestation.trainPlace': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value).required().maxLength(30)
      } else {
        return Validator.value(value).maxLength(30)
      }
    }
  },
  filters: {
    hour: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  components: {
    StationInfo,
    Datepicker,
    Timepicker,
    Multiselect
  }
}
</script>
