<template>
  <div id="prestationRecap">
      <div class="socaContent socaContentNarrow">
          <div class="prestationCreationTitle">{{$t('prestation.new') }}</div>
          <div class="titleStep">{{$t('prestation.create.recap') }}</div>

          <Account @saveAccount="saveAccount" :parcours="parcours" :account="account" :showParcoursNeeds="false" />
          <h3 class="segmentsTitle">{{ $t('prestation.create.prestations') }}</h3>
          <div class="otherPrestations">
            <div v-for="(prestation, index) in prestations" :key="prestation.tempId">
              <div class="segmentBorderLeftContainer" v-if="index % 2 === 0 || index === 0"><div class="segmentBorderLeft"/>
                <RecapSegment :prestation="prestations[index]" :account="account"/>
                <RecapSegment :prestation="prestations[index+1]" :account="account" v-if="prestations[index+1]"/>
              </div>
            </div>
          </div>
          <div class="multiSelectSearchInput" v-if="isOnlyDeparturePrestation() || isOnlyDepartureCorrespondancePrestation()">
            <div class="multiSelectSearchTitle">{{$t('prestation.station.selectArrivalStation') }}</div>
            <div class="stationSearchSelect">
              <Multiselect v-model="selectedStation" :options="stationsList"
              :searchable="true" :internal-search="false" :options-limit="100"
              @search-change="debouncedSearchStation" @input="selectStation" @select="selectStation"
              label="name" track-by="codeUic" :placeholder="`${$t('prestation.station.typeToSearch') }`"
              deselectLabel="" selectLabel="" selectedLabel="">
                <template slot="placeholder">{{$t('prestation.station.chooseStation') }}</template>
                <template slot="noOptions">{{$t('prestation.station.searchNoResults') }}</template>
                <template slot="noResult">{{$t('prestation.station.searchNoResults') }}</template>
                <template slot="singleLabel" slot-scope="value">
                  <span>{{$t('prestation.station.stationOfX', {station: value.option.name}) }}</span>
                </template>
              </Multiselect>
            </div>
          <br>
        </div>
      </div>
      <div class="socaContent socaContentNarrow">
        <button class="button-theme loader" v-if="isLoading"><svgicon class="loadingAnimation" icon="loader" /></button>
        <button @click="checkDuplicateStation()" class="button-theme" :class="{'disableFromSegmentButton': !isStationSelected && (isOnlyDeparturePrestation() || isOnlyDepartureCorrespondancePrestation())}" v-else>{{$t('prestation.create.create')}}</button>
      </div>
      <socalightmodal v-if="showModal" class="operationModalConfirm" :title="$t('prestation.realtime.startOperation')" @close="closeFirstModal" @submit="handleFirstModalSubmit">
        <div class="operationModalTxt">
          <svgicon class="alert" icon="alert" />
          <span v-if="isOnlyDeparturePrestation()" v-html="$t('prestation.create.selectArrivalStationD')"></span>
          <span v-if="isOnlyDepartureCorrespondancePrestation()" v-html="$t('prestation.create.selectArrivalStationDC')"></span>
        </div>
      </socalightmodal>
      <socalightmodal v-if="showNPrestationModal" class="operationModalConfirm" :title="createNModalTitle" @close="closeNPrestationModal" @submit="next">
        <div class="operationModalTxt">
          <svgicon class="alert" icon="alert" />
          <span v-if="displayNoAssistanceForTransporter" v-html="$t('prestation.create.noSocaAssistanceWarning')"></span>
          <span v-if="displayNPrestation" v-html="$t('prestation.create.creatingNPrestationWarning')"></span>
          <span v-if="displayNParcour" v-html="$t('prestation.create.creatingParcourWithMultipleNPrestation')"></span>
        </div>
      </socalightmodal>
  </div>
</template>

<script>
import Account from '@/components/Prestations/Creation/Part/Account'
import RecapSegment from '@/components/Prestations/Creation/Part/RecapSegment'
import validationPresta from '@/services/validatePrestationCreation'
import { filterStations } from '@/services/stationService'
import Multiselect from 'vue-multiselect/src/Multiselect'
import userService from '@/services/userService'
import { debounce } from 'simple-vue-validator/src/utils'
import common from '@/services/common'

export default {
  name: 'PrestationRecap',
  props: { accountData: Object, parcoursData: Object, prestations: Array, sansSuite: Boolean, isLoading: Boolean },
  data () {
    return {
      stationsSearchResult: [],
      account: {},
      parcours: {},
      mine: 'mine',
      stationData: { mine: null },
      otherPrestations: null,
      prestation: null,
      modalOperation: false,
      searchStations: [],
      selectedStation: null,
      modalPrestationN: false,
      displayNoAssistanceForTransporter: false,
      displayNPrestation: false,
      displayNParcour: false
    }
  },
  created () {
    this.debouncedSearchStation = debounce((query) => this.asyncStationSearch(query), 500)
    this.account = this._.assign({}, this.accountData)
    this.parcours = this._.assign({}, this.parcoursData)
    this.prestations.sort((a, b) => (a.tempId > b.tempId) ? 1 : -1)
    this.prestation = this.prestations[0]
    this.getPrestaGare()
    this.otherPrestations = this.prestations.slice(1)
  },
  mounted() {
    this.isOnlyDeparturePrestation()
  },
  methods: {
    selectStation (valSelected) {
      if (valSelected !== null) {
        this.selectedStation = valSelected;
      } else {
        this.selectedStation = null;
      }
    },
    async asyncStationSearch(query) {
      if (!query.length) {
        this.stationsSearchResult = []
        return
      }

      if (this.isOnBoard) {
        this.stationsSearchResult = filterStations(this.$store.getters.possibleStations, query)
        return
      }

      try {
        const results = await this.$backendConnector.searchStations(query)
        this.stationsSearchResult = results
      } catch (error) {
        console.log(error)
      }
    },
    getPrestaGare: function (id) {
      return this.$backendConnector.getGareByUic(this.prestation.garePrestaUic)
        .then(res => {
          this.stationData['mine'] = res
        })
        .catch(error => console.log(error, 'Something went wrong'))
    },
    editPresta (val) {
      let presta = this.otherPrestations.find(p => p.tempId === val.tempId)
      this._.merge(presta, val)
    },
    checkMention () {
      let errorTab = []
      if (this.prestations.length === 1 && this.prestations[0].mention === 'A') {
        return errorTab
      }
      this.prestations.forEach((presta, id) => {
        let correctMention = null
        if (id % 2 === 0) {
          correctMention = ['D', 'DC', 'N'].includes(presta.mention)
        } else {
          correctMention = ['A', 'AC', 'N', undefined].includes(presta.mention)
        }
        if (!correctMention) {
          errorTab.push(correctMention)
          this.$toastService.showToast(this.$t('prestation.create.error.wrongOrder'), null, 'error')
        }
      })
      return errorTab
    },
    next () {
      if (this.parcours.bagage === '') {
        this.parcours.bagage = 0
      }
      if (this.checkMention().length === 0) {
        validationPresta.checkTripConsistency(this.prestations)
          .then(() => {
            this.closeNPrestationModal()
            this.$emit('stepRecapOk', this.prestations)
          })
          .catch(e => {
            this.$toastService.showToast(e.message, null, 'error')
            console.log('fail')
          })
      }
    },
    saveAccount (data) {
      this.$emit('saveAccount', data)
    },
    checkDuplicateStation() {
      if (!this.isOnlyDeparturePrestation() && !this.isOnlyDepartureCorrespondancePrestation()) {
        this.checkDisplayNModal()

        return
      }
      // Allow onboard crew to create a departure without filling in the arrival station (Remove this code when addressing the issue in this ticket: https://ddgcmd.atlassian.net/browse/CRMA-853)
      if (!this.selectedStation && this.isOnBoard) {
        this.modalOperation = true
      } else if (this.prestations[0].codeUic === this.selectedStation.codeUic) { // Verify if the departure station is the same as the arrival station.
        this.$toastService.showToast(this.$t('prestation.error.sameDepartureAndArrival'), null, 'error');
      } else if (this.isOnlyDepartureCorrespondancePrestation()) {
        this.modalOperation = true
        this.prestations[this.prestations.length - 1].gareAutreUic = this.selectedStation.codeUic
      } else {
        this.modalOperation = true
        this.prestations[0].gareAutreUic = this.selectedStation.codeUic
      }
    },
    isOnlyDeparturePrestation() { // Display the arrival input
      return this.prestations.length < 2 && this.prestations[0].mention === 'D'
    },
    isOnlyDepartureCorrespondancePrestation() { // Display the arrival input
      return this.prestations.length % 2 === 1 && this.prestations[this.prestations.length - 1].mention === 'DC'
    },
    checkDisplayNModal() {
      if (this.isOnBoard) {
        this.next()
        return
      }

      const parcourStations = this.$store.getters.parcourStations
      let foundPrestationWithN = false

      this.prestations.forEach(prestation => {
        if (prestation.mention !== "N") return // Ignore prestations without the "N" mention
        foundPrestationWithN = true

        if (this.isGAndCoOrOther) {
          this.displayNPrestation = true
          return
        }

        if (this.isTransporter) {
          const station = parcourStations.find(st => st.codeUic === prestation.codeUic)
          if (!station) return

          if (!station.garanteed) {
            this.displayNPrestation = true
          } else {
            this.displayNoAssistanceForTransporter = true
          }
        }
      })

      // If no prestations with "N" mention were found, do not display any modal.
      if (!foundPrestationWithN) {
        this.next()
        return
      }

      this.modalPrestationN = this.displayNPrestation || this.displayNoAssistanceForTransporter

      // Set modal titles and handle parcour modal
      if (this.displayNPrestation && this.displayNoAssistanceForTransporter) {
        this.displayNParcour = true
        this.displayNPrestation = false
        this.displayNoAssistanceForTransporter = false
        this.createNModalTitle = this.$t('prestation.create.createParcourModal')
      } else if (this.displayNPrestation) {
        this.createNModalTitle = this.$t('prestation.create.createPrestationWithNoAssistanceModal')
      } else if (this.displayNoAssistanceForTransporter) {
        this.createNModalTitle = this.$t('prestation.create.createNPrestationModal')
      }
    },
    // Handles closing the first modal
    closeCreationModal() {
      this.modalOperation = false
    },
    closeNPrestationModal() {
      this.modalPrestationN = false
      this.displayNPrestation = false
      this.displayNoAssistanceForTransporter = false
      this.displayNParcour = true
    },
    async handleFirstModalSubmit() {
      this.modalOperation = false
      await common.delay(1000)
      this.checkDisplayNModal() // Proceed to check if the next modal should be shown
    },
  },
  computed: {
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      if (this.isOnBoard) {
        return this.$store.getters.possibleStations
      }

      return []
    },
    showModal() {
      return this.modalOperation;
    },
    showNPrestationModal() {
      return this.modalPrestationN
    },
    isStationSelected() {
      return this.selectedStation;
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    isTransporter() {
      return userService.isTransporter()
    },
    isGAndCoOrOther() {
      return !userService.isTransporter() && !userService.isOnBoardCrew()
    }
  },
  components: { RecapSegment, Account, Multiselect }
}
</script>

<style lang="scss" scoped>
.loader {
  padding: 0;
}
</style>
