<template>
  <div class="stationTags">
      <div class="stationBadges" v-if="station.spontaneous || station.garanteed">
          <div class="spontaneousIcon" v-if="station.spontaneous" v-tooltip="{ content: $t('station.spontaneous'), trigger: 'hover click'}"><svgicon icon="thunder"  /></div>
          <div class="garanteedIcon" v-if="station.garanteed" v-tooltip="{ content: $t('station.garanteed'), trigger: 'hover click'}"><svgicon icon="star" /></div>
      </div>
      <div class="stationUses" v-if="station.isPMR || station.soca">
          <div class="pmrIcon" v-if="station.isPMR" v-tooltip="{ content: $t('station.pmr'), trigger: 'hover click'}">PMR</div>
          <div class="socaIcon" v-if="station.soca" v-tooltip="{ content: $t('station.useSoca'), trigger: 'hover click'}">SOCA</div>
      </div>
      <div class="stationBadges">
        <div v-if="station.accessibilityLevel === STATION_FULL_ACCESSIBILITY" v-tooltip="{ content: $t('station.fullAccessibility'), trigger: 'hover click'}">
          <svgicon class="icons fullAccessibilityStation" icon="accessible_for_all"/>
        </div>
        <div v-if="station.accessibilityLevel === STATION_PARTIAL_ACCESSIBILITY" v-tooltip="{ content: $t('station.partialAccessibility'), trigger: 'hover click'}">
          <svgicon class="icons partialAccessibilityStation" icon="accessible"/>
        </div>
        <div v-if="station.accessibilityLevel === STATION_LIMITED_ACCESSIBILITY" v-tooltip="{ content: $t('station.limitedAccessibility'), trigger: 'hover click'}">
          <svgicon class="icons limitedAccessibilityStation" icon="barely_accessible"/>
        </div>
        <div v-if="station.accessibilityLevel === STATION_NO_ACCESSIBILITY || station.accessibilityLevel === null" v-tooltip="{ content: $t('station.noAccessibility'), trigger: 'hover click'}">
          <svgicon class="icons noAccessibilityStation" icon="inaccessible"/>
        </div>
        <div class="elevator-icon" v-if="!station.equipment" v-tooltip="{ content: $t('station.unavailableElevatorData'), trigger: 'hover click'}">
          <svgicon class="icons elevatorNok" icon="elevator"/>
        </div>
        <div class="elevator-icon" v-if="elevatorOk" v-tooltip="{ content: $t('station.elevatorOk'), trigger: 'hover click'}">
          <svgicon class="icons elevator" icon="elevator"/>
        </div>
        <div class="elevator-icon" v-if="elevatorNok" v-tooltip="{ content: $t('station.elevatorNok'), trigger: 'hover click'}">
          <svgicon class="icons elevatorNok" icon="elevator"/>
        </div>
        <div class="elevator-icon" v-if="noElevator" v-tooltip="{ content: $t('station.noElevator'), trigger: 'hover click'}">
          <svgicon class="icons elevator" icon="elevator"/>
        </div>
        <div v-if="station.compatibleHardware" v-tooltip="{ content: $t('station.compatibleHardware') + ' : ' +station.compatibleHardware, trigger: 'hover click'}">
          <svgicon class="icons hardware" icon="hardware"/>
        </div>
      </div>
  </div>
</template>

<script>

import { STATION_FULL_ACCESSIBILITY, STATION_PARTIAL_ACCESSIBILITY, STATION_LIMITED_ACCESSIBILITY, STATION_NO_ACCESSIBILITY } from '@/utils/constantsUtils'
import common from '@/services/common'

export default {
  name: 'StationTags',
  props: { station: Object },
  data() {
    return {
      STATION_FULL_ACCESSIBILITY,
      STATION_PARTIAL_ACCESSIBILITY,
      STATION_LIMITED_ACCESSIBILITY,
      STATION_NO_ACCESSIBILITY,
      elevatorOk: false,
      elevatorNok: false,
      noElevator: false
    }
  },
  watch: {
    station: {
      immediate: true,
      deep: true,
      handler(newStation) {
        this.updateElevatorStatus(newStation);
      }
    }
  },
  methods: {
    updateElevatorStatus(station) {
      if (!station.equipment) {
        return
      }
      const equipmentStatus = common.getStationElevaticStatus(station?.equipment)
      this.elevatorOk = equipmentStatus.elevatorOk
      this.elevatorNok = equipmentStatus.elevatorNok
      this.noElevator = equipmentStatus.noElevator
    }
  }
}
</script>
