<template>
  <div id="prestationReport">
    <div class="socaContent">
      <div class="titleStep">{{$t('prestation.report.title') }}</div>
      <h3>{{ $t('prestation.title.reported') }}</h3>
      <div v-if="segments[0][0]">
        <div v-for="(segment, idx) in segments" :key="idx">
          <div v-if="idx >= segmentOffset">
            <div class="headerSegment grid-x grid-margin-x">
              <div class="cell large-6 small-4">
                <h3 class="segmentsTitle">{{ $t('report.segment.number') }} {{idx + 1}}</h3>
              </div>
              <div class="cell large-6 small-8 segmentUtilButtons" v-if="(segment[0] || {}).herokuExternalId && isPrestaReportable(segment[0].ogIdx)">
                <button class="reportChooseReasonButton" @click="reportSegment((segment[0] || {}).herokuExternalId)">{{$t('prestation.report.report')}}</button>
                <span @click="deleteSegment((segment[0] || {}).herokuExternalId)" class="delSegment" ><svgicon class="trashIcon" icon="trash"/></span>
              </div>
              <div class="cell large-6 small-8 segmentUtilButtons" v-else-if="(segment[0] || {}).herokuExternalId && idx !== 0">
                <ReportImpossible :contactStationUIC="segment[1] ? originalPrestations[segment[0].ogIdx - 1].gareAutreUic : originalPrestations[segment[0].ogIdx].gareAutreUic" />
              </div>
            </div>
            <div class="segmentBorderLeftContainer"><div class="segmentBorderLeft" />
              <div class="grid-x grid-margin-x">
                <div class="cell large-6 small-12" v-if="!segment[1] && (segment[0].segmentPart === 'to')" />
                <ReportSegment class="departure cell large-6 small-12" @update="onUpdatePresta" v-model="originalPrestations[segment[0].ogIdx]" :parcours="parcours" :availableDates="availableDates" :reportable="isPrestaReportable(segment[0].ogIdx)" />
                <ReportSegment class="arrival cell large-6 small-12" @update="onUpdatePresta" v-model="originalPrestations[segment[1].ogIdx]" :parcours="parcours" :availableDates="availableDates" :reportable="isPrestaReportable(segment[0].ogIdx)" v-if="segment[1]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttonAddSegment">
        <button class='addSegment' @click='addSegment' v-if='canAddSegment'><svgicon class="plusIcon" icon="plus"/>{{$t('report.segment.add')}}</button>
      </div>
      <Account :parcours="parcours" :account="account" />
    </div>
    <div class="socaContent">
      <button class="button-theme loader" v-if="isLoading"><svgicon class="loadingAnimation" icon="loader" /></button>
      <button @click="nextNew" class="button-theme" v-else-if="validateIsVisible">{{$t('common.validate')}}</button>
    </div>
    <socalightmodal v-if="modalConfirm"
      class="reportModalConfirm"
      :title="$t('prestation.segment.modalTitle')"
      @close="modalConfirm = false"
      @submit="reportPrestas(toReport)">
      <div class="reportModalTxt">
        <svgicon class="alert" icon="alert"/>
        {{ $t('report.modal.ok')}}
      </div>
      <span class="subtitle">{{ $t('report.modal.chooseReason')}}</span>
      <div class="formElement darkFormElement">
        <select v-model="reportReason" class="cell small-8 large-9 modalReportReasons">
          <option :value="null" selected>{{$t('prestation.segment.reasonSelect')}}</option>
          <option v-for="motif in motifs" :value="motif" :key="motif">{{ $t('prestation.reason.'+motif) }}</option>
        </select>
      </div>
    </socalightmodal>
    <ConfirmModal :title="$t('report.modal.deleteSegment')" v-if="modalConfirmDelete" @cancel="modalConfirmDelete = false" @confirm="removeSegment()" />
    <socalightmodal v-if="showReportModal" class="reportModalConfirm" :title="$t('prestation.report.title')" @close="closeReportModal" @submit="confirmReport">
      <div class="operationModalTxt">
        <svgicon class="alert" icon="alert"/>
        <span v-html="$t('prestation.report.reportModal')"></span>
      </div>
    </socalightmodal>
  </div>
</template>

<script>
import Account from '@/components/Prestations/Creation/Part/Account'
import ReportSegment from '@/components/Prestations/Report/ReportSegment'
import ReportImpossible from '@/components/Prestations/Report/ReportImpossible'
import ConfirmModal from '@/components/Common/ConfirmModal'
import validationPresta from '@/services/validatePrestationCreation'
import moment from 'moment'

export default {
  name: 'Report',
  data () {
    return {
      segmentOffset: 0,
      originalPrestations: [],
      prestationsToUpdate: [],
      account: {},
      parcours: {},
      modalConfirm: false,
      modalConfirmDelete: false,
      availableDates: null,
      motifs: [],
      reportReason: null,
      prestations: [],
      canChangeGarantee: false,
      toReport: null,
      toDelete: null,
      isLoading: false,
      reportModal: false
    }
  },
  created () {
    let prestation
    this.motifs = this.$sncfRefs.getPrestaReasonsTechnicalNames()
    this.reportReason = this.$route.params.reason
    const prestationHcId = this.$route.params.herokuExternalId
    this.$backendConnector.getPrestationForReport(prestationHcId)
      .then((presta) => (prestation = presta))
      .then(() => this.$backendConnector.getParcours(prestation.parcoursHerokuExternalId))
      .then((parcours) => (this.parcours = parcours))
      .then(() => this.$backendConnector.getAccount(this.parcours.accountHerokuExternalId))
      .then((account) => (this.account = account))
      .then(() => this.$backendConnector.getPrestations({ searchParams: { parcoursHerokuExternalId: prestation.parcoursHerokuExternalId } }))
      .then((result) => (this.originalPrestations = result.docs.filter(p => ['todo', 'notDone'].includes(p.statusTN))))
      .then(() => { // on cache les segments précédent les prestations à reporter
        this.segments.forEach((segment, i) => {
          segment.forEach((p) => {
            if (p.herokuExternalId === prestation.herokuExternalId) {
              this.segmentOffset = i
            }
          })
        })
      })
      .then(() => { // on check les prestas impossibles à reporter
        for (let i = 0; i < this.segments.length; i++) {
          const idx = this.segments[i][0].ogIdx
          if (!this.isPrestaReportable(idx)) {
            this.originalPrestations[idx].checked = true
            if (this.segments[i][1]) {
              this.originalPrestations[idx + 1].checked = true
            }
          }
        }
      })
      .then(() => { // on check les prestas des segments non affichés
        let i = 0
        while (this.originalPrestations[i].herokuExternalId !== prestationHcId) {
          this.originalPrestations[i].checked = true
          i++
        }
      })
      .then(() => {
        this.availableDates = {
          start: prestation.datePrestation,
          end: moment(prestation.datePrestation).add(1, 'days').format()
        }
      })
      .then(() => {
        this.reportPrestas(prestationHcId)
      })
  },
  methods: {
    reportSegment (hcid) {
      this.modalConfirm = true
      this.toReport = hcid
    },
    deleteSegment (hcid) {
      this.modalConfirmDelete = true
      this.toDelete = hcid
    },
    nextNew () {
      this.isLoading = true
      validationPresta.checkTripConsistency(this.originalPrestations).then(() => {
        this.reportModal = true
      }).catch(error => {
        this.$toastService.showToast(this.$t(error.message), null, 'error')
      }).finally(() => (this.isLoading = false))
    },
    confirmReport() {
      this.reportModal = false
      this.isLoading = true

      const userUics = this.$store.getters.contextStations.map(s => s.codeUic)
      for (let i = 0; i < this.originalPrestations.length; i++) {
        if (!this.originalPrestations[i].herokuExternalId) {
          if (this.originalPrestations[i].mention === 'D' &&
            (userUics.includes(this.originalPrestations[i].garePrestaUic) ||
            (this.originalPrestations[i].garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed))) {
            this.originalPrestations[i].state = this.$sncfRefs.getPrestaStatesByTechnicalName().accepted
          }
        }
      }
      Promise.all([
        ...this.prestationsToUpdate.map(p => this.$backendConnector.updatePrestation(p)),
        ...this.originalPrestations.filter(p => !p.herokuExternalId).map(p => this.$backendConnector.createPrestation(p))
      ]).then(() => {
        this.$toastService.showToast(this.$t('prestation.report.done'), null, 'success')
        this.$router.push('/')
      }).catch(error => {
        this.$toastService.showToast(this.$t(error.message), null, 'error')
        console.log(error)
      }).finally(() => (this.isLoading = false))
    },
    closeReportModal() {
      this.reportModal = false
    },
    closeModal () {
      this.syncSegment(this.toReport)
      this.toReport = false
    },
    addSegment () {
      let lastPresta = this.originalPrestations[this.originalPrestations.length - 1]
      const newDeparture = {
        mention: this.$sncfRefs.getMentionsByTechnicalName().transitDeparture.sigle,
        garantee: lastPresta.garantee,
        garePrestaUic: lastPresta.garePrestaUic,
        service: lastPresta.service,
        statusTN: 'todo',
        transportMode: lastPresta.transportMode,
        datePrestation: lastPresta.datePrestation ? new Date(moment(lastPresta.datePrestation).startOf('day')) : null,
        parcoursHerokuExternalId: this.parcours.herokuExternalId,
        tripId: lastPresta.tripId,
        carrierApiUserId: lastPresta.carrierApiUserId,
        segmentPart: 'from'
      }
      const newArrival = {
        gareAutreUic: lastPresta.garePrestaUic,
        garantee: lastPresta.garantee,
        service: lastPresta.service,
        statusTN: 'todo',
        transportMode: lastPresta.transportMode,
        datePrestation: lastPresta.datePrestation ? new Date(moment(lastPresta.datePrestation).startOf('day')) : null,
        parcoursHerokuExternalId: this.parcours.herokuExternalId,
        tripId: lastPresta.tripId,
        carrierApiUserId: lastPresta.carrierApiUserId,
        segmentPart: 'to'
      }
      this.originalPrestations.push(newDeparture, newArrival)
    },
    removeSegment () {
      const hcid = this.toDelete
      const prestation = this.originalPrestations.find(p => (p.herokuExternalId === hcid))
      this.removePresta(hcid)
      this.originalPrestations.forEach((p) => {
        if (p.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported &&
            p.segmentPart !== prestation.segmentPart &&
            p.gareAutreUic === prestation.garePrestaUic) {
          this.removePresta(p.herokuExternalId)
        }
      })
      this.modalConfirmDelete = false
    },
    removePresta (hcid) {
      for (let i = 0; i < this.originalPrestations.length; i++) {
        if (this.originalPrestations[i].herokuExternalId === hcid) {
          this.prestationsToUpdate.push({
            herokuExternalId: this.originalPrestations[i].herokuExternalId,
            reason: 'postponementDeparture',
            statusTN: 'notDone',
            state: 'reportée'
          })
        }
      }
      this.originalPrestations = this.originalPrestations.filter(p => p.herokuExternalId !== hcid)
    },
    reportPrestas (hcid) {
      const prestation = this.originalPrestations.find(p => (p.herokuExternalId === hcid))
      this.reportPresta(hcid)
      this.originalPrestations.forEach((p) => {
        if (p.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported &&
            p.segmentPart !== prestation.segmentPart &&
            p.gareAutreUic === prestation.garePrestaUic) {
          this.reportPresta(p.herokuExternalId)
        }
      })
      this.modalConfirm = false
    },
    reportPresta (hcid) {
      for (let i = 0; i < this.originalPrestations.length; i++) {
        const originalPrestation = this.originalPrestations[i]
        const isDeparture = (originalPrestation.segmentPart === 'from' && originalPrestation.mention === 'D')
        if (originalPrestation.herokuExternalId === hcid) {
          this.prestationsToUpdate.push({
            herokuExternalId: originalPrestation.herokuExternalId,
            state: this.$sncfRefs.getPrestaStatesByTechnicalName().reported,
            statusTN: isDeparture ? 'reported' : 'notDone',
            reason: isDeparture ? this.reportReason : 'postponementDeparture',
            autoAccepted: false
          })
          let garantee = ['customerDelay', 'trainRemoved', 'trainRemovedOtherStation'].includes(this.reportReason) ? this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed : originalPrestation.garantee
          let autoAccepted = isDeparture || (garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed)
          this.$set(this.originalPrestations, i, {
            garantee,
            gareAutreUic: originalPrestation.gareAutreUic,
            garePrestaUic: originalPrestation.garePrestaUic,
            transportMode: originalPrestation.transportMode,
            parcoursHerokuExternalId: this.parcours.herokuExternalId,
            carrierApiUserId: originalPrestation.carrierApiUserId,
            segmentPart: originalPrestation.segmentPart,
            mention: originalPrestation.mention,
            serviceBeforePostponement: originalPrestation.service,
            service: 'Spontané',
            tripId: originalPrestation.tripId,
            datePrestation: null,
            trainNumber: null,
            missionCode: null,
            transporter: null,
            trainPlace: null,
            trainCar: null,
            statusTN: 'todo',
            checked: false,
            state: autoAccepted ? this.$sncfRefs.getPrestaStatesByTechnicalName().accepted : null,
            autoAccepted
          })
        }
      }
    },
    onUpdatePresta (updatedPresta) {
      for (let i = 0; i < this.originalPrestations.length; i++) {
        // on checke la presta
        if ((this.originalPrestations[i].gareAutreUic === updatedPresta.gareAutreUic) && (this.originalPrestations[i].segmentPart === updatedPresta.segmentPart)) {
          this.originalPrestations[i].checked = true
        }
        // on synchronise les 2 prestations du même segment
        if ((this.originalPrestations[i].garePrestaUic === updatedPresta.gareAutreUic) && (this.originalPrestations[i].segmentPart !== updatedPresta.segmentPart)) {
          this.originalPrestations[i].datePrestation = this.originalPrestations[i].datePrestation || new Date(moment(updatedPresta.datePrestation).startOf('day'))
          this.originalPrestations[i].gareAutreUic = updatedPresta.garePrestaUic
          this.originalPrestations[i].trainNumber = updatedPresta.trainNumber
          this.originalPrestations[i].missionCode = updatedPresta.missionCode
          this.originalPrestations[i].transporter = updatedPresta.transporter
          this.originalPrestations[i].trainPlace = updatedPresta.trainPlace
          this.originalPrestations[i].trainCar = updatedPresta.trainCar
          if ((this.originalPrestations[i].segmentPart === 'to') && updatedPresta.nextStops) {
            for (let j = 0; j < updatedPresta.nextStops.length; j++) {
              if (this.originalPrestations[i].garePrestaUic === updatedPresta.nextStops[j].codeUic) {
                this.originalPrestations[i].datePrestation = new Date(updatedPresta.nextStops[j].arrivalTime)
                this.originalPrestations[i].pivProposedsStops = updatedPresta.nextStops
              }
            }
          }
        }
        // on alerte si le delay de correspondance est trop court
        if ((this.originalPrestations[i].mention === this.$sncfRefs.getMentionsByTechnicalName().transitArrival.sigle) && this.originalPrestations[i + 1]) {
          const delay = moment(this.originalPrestations[i + 1].datePrestation).diff(moment(this.originalPrestations[i].datePrestation), 'minutes')
          if (delay > 0 && delay <= 10) {
            this.$toastService.showToast(this.$t('prestation.report.limit'), null, 'info')
          }
        }
        if ((this.originalPrestations[i].mention === this.$sncfRefs.getMentionsByTechnicalName().transitDeparture.sigle) && this.originalPrestations[i - 1]) {
          const delay = moment(this.originalPrestations[i].datePrestation).diff(moment(this.originalPrestations[i - 1].datePrestation), 'minutes')
          if (delay > 0 && delay <= 10) {
            this.$toastService.showToast(this.$t('prestation.report.limit'), null, 'info')
          }
        }
      }
    },
    isPrestaReportable: function (idx) {
      if (this.segmentOffset !== 0) {
        return true
      }
      return ((this.originalPrestations[idx].segmentPart === 'from') && (this.originalPrestations[idx - 1] ? (this.originalPrestations[idx - 1].segmentPart === 'to') : true))
    }
  },
  computed: {
    segments: function () {
      const segments = [[]]
      for (let i = 0; i < this.originalPrestations.length; i++) {
        const prestation = { ...this.originalPrestations[i], ogIdx: i }
        const lastSegmentParts = segments[segments.length - 1].map(p => p.segmentPart)
        if (lastSegmentParts.includes('to')) {
          segments.push([prestation])
        } else if ((lastSegmentParts.includes('from')) && (prestation.segmentPart === 'from')) {
          segments.push([prestation])
        } else {
          segments[segments.length - 1].push(prestation)
        }
      }
      return segments
    },
    showReportModal() {
      return this.reportModal
    },
    canAddSegment: function () {
      return this.originalPrestations && this.originalPrestations.length >= 1 && this.originalPrestations[this.originalPrestations.length - 1].mention === this.$sncfRefs.getMentionsByTechnicalName().transitArrival.sigle
    },
    validateIsVisible: function () {
      return this.originalPrestations && this.originalPrestations.every(p => p.checked)
    }
  },
  components: { ReportSegment, Account, ConfirmModal, ReportImpossible }
}
</script>

<style lang="scss" scoped>
.loader {
  padding: 0;
}
</style>
