import backendConnector from '@/services/backendConnector'
import store from '@/store/store.js'
import _ from 'lodash'

export const ADMINNAT = 'ADMINNAT'
export const AU = 'AU'
export const ONBOARD = 'ONBOARD'
export const ENCADRANT_GAREETCO = 'ADMINISTRATOR'
export const ENCADRANT_TRANSPO = 'ADMINTRANSPO'
export const REFERENT_GAREETCO = 'REFERENT'
export const REFERENT_TRANSPO = 'REFTRANSPO'
export const OEUVRANT_GAREETCO = 'USER'
export const OEUVRANT_TRANSPO = 'USERTRANSPO'
export const TRANSPORTER = 'TRANSPORTER'
export const CONSULTANT = 'CONSULTANT'
export const DT = 'AGENCY'
export const SUPERVISOR = 'SUPERVISOR'
export const REFERENT = 'REFERENT_USER'
export const OPERATOR = 'OPERATOR'

const prepUserForUpsert = function (user) {
  user = _.cloneDeep(user)
  // we keep only id and uic
  if (user.stations) {
    user.stations = user.stations.map((s) => {
      return {
        codeUic: s.codeUic
      }
    })
  }
  if (user.agencies) {
    user.agencies = user.agencies.map((agency) => {
      return agency.code
    })
  }
  if (user.regions) {
    user.regions = user.regions.map((region) => {
      return region.codeRegion
    })
  }
  return user
}

export default {
  loadUserInfos: async function () {
    const user = await backendConnector.whoAmI()
    store.commit('setUser', user)

    if (user) {
      const userRestrictedRefs = await backendConnector.getMyRestrictedRefs()
      const userStations = await backendConnector.getMyStations()
      store.commit('setUserRestrictedRefs', userRestrictedRefs)
      store.commit('setUserStations', userStations)
    }
  },
  eraseUser () {
    return store.commit('eraseUser')
  },
  getUsers (filters, params) {
    return backendConnector.getUsers(filters, params)
  },
  getUser (code) {
    return backendConnector.getUser(code)
  },
  createUser (user) {
    return backendConnector.createUser(prepUserForUpsert(user))
  },
  updateUser (user) {
    return backendConnector.updateUser(prepUserForUpsert(user))
  },
  removeUser (userCode) {
    return backendConnector.removeUser(userCode)
  },
  isOnBoardCrew () {
    return store.getters.userRole === ONBOARD
  },
  isAdministrator: () => store.getters.userRole === ENCADRANT_GAREETCO,
  isAdminTranspo: () => store.getters.userRole === ENCADRANT_TRANSPO,
  isTransporter: () => store.getters.userRole === TRANSPORTER,
  isReferent: () => store.getters.userRole === REFERENT_GAREETCO,
  isRefTranspo: () => store.getters.userRole === REFERENT_TRANSPO,
  isAdminNat: () => store.getters.userRole === ADMINNAT,
  isDT: () => store.getters.userRole === DT,
  isManagerSoca: () => [ADMINNAT, DT, AU].includes(store.getters.userRole),
}
